import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { AuthorizationManagerAbi, BendExchangeAbi, StrategyStandardSaleForFixedPriceAbi } from 'modules/exchange/abis'
import { ERC20_ABI, useContract } from 'wallet-module'

import {
  AIRDROP_DISTRIBUTION_CONTRACT_ABI,
  BEND_COMPETITION_ABI,
  BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI,
  BEND_STAKE_ABI,
  BNFT_ABI,
  BOUND_PUNK_GATEWAY_ABI,
  CRYPTO_PUNKS_MARKET_ABI,
  DEBT_TOKEN_ABI,
  EIP2981_ABI,
  ENS_ABI,
  ENS_PUBLIC_RESOLVER_ABI,
  ENS_REGISTRAR_ADDRESSES,
  ERC721_ABI,
  FEE_DISTRIBUTOR_ABI,
  LEND_POOL_ABI,
  LEND_POOL_ADDRESS_PROVIDER_ABI,
  LEND_POOL_LOAN_ABI,
  LEND_POOL_SWAP_ABI,
  MERKLE_DISTRIBUTOR_ABI,
  PUNK_GATEWAY_ABI,
  UI_POOL_DATA_PROVIDER_ABI,
  UNISWAP_PAIR_ABI,
  VE_BEND_ABI,
  WBTC_GATEWAY_ABI,
  WETH_ABI,
  WETH_GATEWAY_ABI,
  WRAPPED_PUNKS_ABI
} from '../constants'

export function useILendPoolContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, LEND_POOL_ABI, withSignerIfPossible)
}

export function useERC20Contract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, ERC20_ABI, withSignerIfPossible)
}

export function useERC721Contract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, ERC721_ABI, withSignerIfPossible)
}

export function useLendPoolLoanContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, LEND_POOL_LOAN_ABI, withSignerIfPossible)
}

export function useWethGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, WETH_GATEWAY_ABI, withSignerIfPossible)
}

export function useWbtcGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, WBTC_GATEWAY_ABI, withSignerIfPossible)
}

export function usePunkGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, PUNK_GATEWAY_ABI, withSignerIfPossible)
}

export function useCryptoPunkContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, CRYPTO_PUNKS_MARKET_ABI, withSignerIfPossible)
}

export function useWPunkContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, WRAPPED_PUNKS_ABI, withSignerIfPossible)
}

export function useUiPoolDataProviderContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, UI_POOL_DATA_PROVIDER_ABI, withSignerIfPossible)
}

export function useBendCompetitionContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, BEND_COMPETITION_ABI, withSignerIfPossible)
}

export function useBendIncentivesControllerContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI, withSignerIfPossible)
}

export function useWethContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useAddressProviderContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, LEND_POOL_ADDRESS_PROVIDER_ABI, withSignerIfPossible)
}

export function useMerkleDistributorContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, MERKLE_DISTRIBUTOR_ABI, withSignerIfPossible)
}

export function useVeBendContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, VE_BEND_ABI, withSignerIfPossible)
}

export function useFeeDistributorContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, FEE_DISTRIBUTOR_ABI, withSignerIfPossible)
}

export function useUniswapPairContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, UNISWAP_PAIR_ABI, withSignerIfPossible)
}

export function useDebtTokenContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, DEBT_TOKEN_ABI, withSignerIfPossible)
}

export function useBNFTContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, BNFT_ABI, withSignerIfPossible)
}

export function useBoundPunkGatewayContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, BOUND_PUNK_GATEWAY_ABI, withSignerIfPossible)
}

export function useAirdropDistributionContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, AIRDROP_DISTRIBUTION_CONTRACT_ABI, withSignerIfPossible)
}

export function useBendStakeContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, BEND_STAKE_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useEIP2981Contract(address?: string | null, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, EIP2981_ABI, withSignerIfPossible)
}

export function useBendExchangeContract(address?: string | null, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, BendExchangeAbi, withSignerIfPossible)
}

export function useAuthorizationManagerContract(address?: string | null, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, AuthorizationManagerAbi, withSignerIfPossible)
}

export function useStrategyStandardSaleForFixedPriceContract(address?: string | null, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? address : undefined, StrategyStandardSaleForFixedPriceAbi, withSignerIfPossible)
}

export function useLendPoolSwapContract(address: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, LEND_POOL_SWAP_ABI, withSignerIfPossible)
}
