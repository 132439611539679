import { BAKC_ADDRESS, BAYC_ADDRESS, MAYC_ADDRESS } from 'modules/bend/constants'
import { ChainId } from 'modules/wallet-module'

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '686868'

const ADDRESSES: any = {
  [ChainId.MERLIN_TESTNET]: {
    BEND_STAKE_MANAGER_ADDRESS: '',
    BEND_NFT_POOL_ADDRESS: '',
    BEND_COIN_POOL_ADDRESS: '',
    STAKED_BAYC_ADDRESS: '',
    STAKED_MAYC_ADDRESS: '',
    STAKED_BAKC_ADDRESS: '',
    BOUND_STAKED_BAYC_ADDRESS: '',
    BOUND_STAKED_MAYC_ADDRESS: '',
    COMPOUND_V1_MIGRATOR_ADDRESS: '',
    POOL_VIEWER_ADDRESS: '',
    APECOIN_PRICE_FEED: '', // not used in DEV environment
    LENDING_MIGRATOR_ADDRESS: '',
    AAVE_LEND_POOL_ADDRESS: '',
    BEND_NFT_VAULT: ''
  },
  [ChainId.MERLIN]: {
    BEND_STAKE_MANAGER_ADDRESS: '',
    BEND_NFT_POOL_ADDRESS: '',
    BEND_COIN_POOL_ADDRESS: '',
    STAKED_BAYC_ADDRESS: '',
    STAKED_MAYC_ADDRESS: '',
    STAKED_BAKC_ADDRESS: '',
    BOUND_STAKED_BAYC_ADDRESS: '',
    BOUND_STAKED_MAYC_ADDRESS: '',
    COMPOUND_V1_MIGRATOR_ADDRESS: '',
    POOL_VIEWER_ADDRESS: '',
    APECOIN_PRICE_FEED: '',
    LENDING_MIGRATOR_ADDRESS: '',
    AAVE_LEND_POOL_ADDRESS: '',
    BEND_NFT_VAULT: ''
  }
}

export const BEND_STAKE_MANAGER_ADDRESS = process.env.NEXT_PUBLIC_BEND_STAKE_MANAGER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_STAKE_MANAGER_ADDRESS
export const BEND_NFT_POOL_ADDRESS = process.env.NEXT_PUBLIC_BEND_NFT_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_NFT_POOL_ADDRESS
export const BEND_COIN_POOL_ADDRESS = process.env.NEXT_PUBLIC_BEND_COIN_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COIN_POOL_ADDRESS

export const STAKED_BAYC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_BAYC_ADDRESS
export const STAKED_MAYC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_MAYC_ADDRESS
export const STAKED_BAKC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_BAKC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_BAKC_ADDRESS

export const BOUND_STAKED_BAYC_ADDRESS = process.env.NEXT_PUBLIC_BOUND_STAKED_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_STAKED_BAYC_ADDRESS
export const BOUND_STAKED_MAYC_ADDRESS = process.env.NEXT_PUBLIC_BOUND_STAKED_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_STAKED_MAYC_ADDRESS

export const COMPOUND_V1_MIGRATOR_ADDRESS = process.env.NEXT_PUBLIC_COMPOUND_V1_MIGRATOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].COMPOUND_V1_MIGRATOR_ADDRESS

export const POOL_VIEWER_ADDRESS = process.env.NEXT_PUBLIC_POOL_VIEWER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].POOL_VIEWER_ADDRESS

export const APECOIN_PRICE_FEED = process.env.NEXT_PUBLIC_APECOIN_PRICE_FEED || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_PRICE_FEED

export const LENDING_MIGRATOR_ADDRESS = process.env.NEXT_PUBLIC_LENDING_MIGRATOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].LENDING_MIGRATOR_ADDRESS
export const AAVE_LEND_POOL_ADDRESS = process.env.NEXT_PUBLIC_AAVE_LEND_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].AAVE_LEND_POOL_ADDRESS

export const BEND_NFT_VAULT = process.env.NEXT_PUBLIC_BEND_NFT_VAULT || ADDRESSES[DEFAULT_CHAIN_ID].BEND_NFT_VAULT

export enum APE_V2_POOL_ADDRESS {
  'BAYC' = BAYC_ADDRESS,
  'MAYC' = MAYC_ADDRESS,
  'BAKC' = BAKC_ADDRESS
}

export enum APE_V2_STAKED_POOL_ADDRESS {
  'BAYC' = STAKED_BAYC_ADDRESS,
  'MAYC' = STAKED_MAYC_ADDRESS,
  'BAKC' = STAKED_BAKC_ADDRESS
}
