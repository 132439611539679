// import { OKXWallet } from '@okwallet/web3-react-okxwallet'
import { OKXWallet } from '@donatello_benddao/web3-react-okxwallet'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector, Web3ReactHooks } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { Connector } from '@web3-react/types'

import { SupportedChainId } from '../constants/chains'
import { RPC_URLS } from '../constants/networks'
import { RPC_PROVIDERS } from '../constants/providers'

import { TrustWallet } from './trustWallet'

const DEFAULT_CHAIN_ID = Number(process.env.NEXT_PUBLIC_CHAIN_ID) || 5

export enum ConnectionType {
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE',
  OKX_WALLET = 'OKX_WALLET',
  TRUST_WALLET = 'TRUST_WALLET'
}

export interface Connection {
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  actions => new Network({ actions, urlMap: RPC_PROVIDERS, defaultChainId: DEFAULT_CHAIN_ID })
)
export const networkConnection: Connection = {
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK
}

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(actions => new MetaMask({ actions, onError }))
export const injectedConnection: Connection = {
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED
}

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>(actions => new GnosisSafe({ actions }))
export const gnosisSafeConnection: Connection = {
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE
}

const [okxWallet, okxHooks] = initializeConnector<OKXWallet>(actions => new OKXWallet({ actions }))
export const okxConnection: Connection = {
  connector: okxWallet,
  hooks: okxHooks,
  type: ConnectionType.OKX_WALLET
}

const [trustWallet, trustHooks] = initializeConnector<TrustWallet>(actions => new TrustWallet({ actions }))
export const trustWalletConnection: Connection = {
  connector: trustWallet,
  hooks: trustHooks,
  type: ConnectionType.TRUST_WALLET
}

// const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnect>(actions => {
//   // Avoid testing for the best URL by only passing a single URL per chain.
//   // Otherwise, WC will not initialize until all URLs have been tested (see getBestUrl in web3-react).
//   const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
//     (map, [chainId, urls]) => ({
//       ...map,
//       [chainId]: urls[0]
//     }),
//     {}
//   )
//   return new WalletConnect({
//     actions,
//     options: {
//       rpc: RPC_URLS_WITHOUT_FALLBACKS,
//       qrcode: true
//     },
//     onError
//   })
// })
// export const walletConnectConnection: Connection = {
//   connector: web3WalletConnect,
//   hooks: web3WalletConnectHooks,
//   type: ConnectionType.WALLET_CONNECT
// }

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[DEFAULT_CHAIN_ID as SupportedChainId][0],
        appName: 'BendDAO',
        appLogoUrl: '/images/bdin-logo-white.png',
        reloadOnDisconnect: false
      },
      onError
    })
)
export const coinbaseWalletConnection: Connection = {
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET
}
