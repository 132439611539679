import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { JsonRpcProvider, WebSocketProvider } from '@ethersproject/providers'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
// Hooks
import { useWeb3React } from '@web3-react/core'
import { Network } from '@web3-react/network'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { getCookie } from 'cookies-next'
import moment from 'moment'

// Components
import { useAppNotificationContext } from 'components/common/app-notofications/context'

// Interfaces
import { ReducerActionProps, WalletContextProps, WalletStateProps } from './interfaces'

const initialState: WalletStateProps = {
  walletModalOpen: false,
  chainEnabled: false,
  transactions: [],
  lastBlockNumber: 0,
  waitTxConfirm: false,
  httpProvider: undefined,
  wsProvider: undefined,
  selectedWalletType: undefined,
  showSpinner: false
}

const reducer = (state: WalletStateProps, { type, payload }: ReducerActionProps) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...payload }
    default:
      return state
  }
}

export const WalletContext = React.createContext<WalletContextProps>({
  ...initialState
})
const WAIT_BLOCK_NUMBER = 2

export const shouldSpinnerStop = (
  lastBlockNumber: number,
  tx: {
    addedTime: number
    receipt?: any
    lastCheckedBlockNumber?: number
    confirmed: boolean
  }
): boolean => {
  if (!tx.receipt || !tx.confirmed) return false

  const blocksSinceReceipt = lastBlockNumber - tx.receipt.blockNumber
  if (blocksSinceReceipt < WAIT_BLOCK_NUMBER) return false

  return true
}

export const shouldCheck = (
  lastBlockNumber: number,
  tx: {
    addedTime: number
    receipt?: {}
    lastCheckedBlockNumber?: number
    confirmed: boolean
  }
): boolean => {
  if (tx.receipt) return false
  if (tx.confirmed) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (moment().unix() - tx.addedTime) / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

const selectedWalletType = getCookie('selectedWalletType')

const WalletContextProvider: React.FC = ({ children }) => {
  const [WalletState, WalletDispatch] = React.useReducer(reducer, initialState)
  const { account, provider, chainId, connector } = useWeb3React()
  const { lastBlockNumber, transactions, showSpinner } = WalletState

  const queryClient = useQueryClient()
  const { addAppNotification } = useAppNotificationContext()

  const providerHttpRef = useMemo(() => new JsonRpcProvider(String(process.env.NEXT_PUBLIC_NETWORK_URL)), [])
  const providerWsRef = useMemo(
    () => (process.env.NEXT_PUBLIC_NETWORK_URL_WSS !== '' ? new WebSocketProvider(String(process.env.NEXT_PUBLIC_NETWORK_URL_WSS)) : null),
    []
  )

  useEffect(() => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        wsProvider: providerWsRef
      }
    })
  }, [providerWsRef])

  useEffect(() => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        httpProvider: providerHttpRef
      }
    })
  }, [providerHttpRef])

  const library = useMemo(() => {
    if (!connector || connector instanceof Network || connector instanceof CoinbaseWallet) return providerWsRef || providerHttpRef
    else return provider
  }, [connector, providerWsRef, providerHttpRef, provider])

  const lastBlockNumberChecked = useRef(0)

  const blockNumberCallback = useCallback((blockNumber: number) => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        lastBlockNumber: blockNumber
      }
    })
  }, [])

  const addTransaction = useCallback(
    (tx: any, callback: () => void = () => {}) => {
      const hash = tx.hash

      const txs = transactions ?? {}
      txs[hash] = {
        hash,
        from: account,
        addedTime: moment().unix(),
        confirmed: false,
        callback
      }
      WalletDispatch({
        type: 'UPDATE',
        payload: {
          transactions: txs,
          waitTxConfirm: true,
          showSpinner: true
        }
      })
    },
    [transactions, account]
  )
  const txHashChecking = useRef('')
  useEffect(() => {
    if (!library || !lastBlockNumber || lastBlockNumberChecked.current >= lastBlockNumber) return
    lastBlockNumberChecked.current = lastBlockNumber
    const keys = Object.keys(transactions)
    if (showSpinner) {
      let shouldStop = true
      for (let i = 0; i < keys.length; i++) {
        const element = transactions[keys[i]]
        const check = shouldSpinnerStop(lastBlockNumber, element)
        if (check === false) shouldStop = false
      }
      if (shouldStop === true) {
        WalletDispatch({
          type: 'UPDATE',
          payload: {
            showSpinner: false
          }
        })
        queryClient?.refetchQueries?.({ stale: true })
        addAppNotification?.(
          {
            key: 'platform-transaction-success',
            message: 'Latest state from blockchain has been updated.',
            type: 'default'
          },
          {
            timeout: 10000,
            autoHide: true
          }
        )
      }
    }
    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        if (hash === txHashChecking.current) return
        txHashChecking.current = hash
        library
          .getTransactionReceipt(hash)
          .then((receipt: any) => {
            if (receipt) {
              const txs = transactions
              txs[hash].confirmed = true
              txs[hash].receipt = {
                blockHash: receipt.blockHash,
                blockNumber: receipt.blockNumber,
                contractAddress: receipt.contractAddress,
                from: receipt.from,
                status: receipt.status,
                to: receipt.to,
                transactionHash: receipt.transactionHash,
                transactionIndex: receipt.transactionIndex
              }
              WalletDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs
                }
              })
              WalletDispatch({
                type: 'UPDATE',
                payload: {
                  waitTxConfirm: false
                }
              })
              if (receipt.status === 1) {
                txs[hash].callback({ tx: receipt.transactionHash, status: 'success' })
              } else {
                txs[hash].callback({ tx: receipt.transactionHash, status: 'fail' })
              }
              txHashChecking.current = ''
            } else {
              const txs = transactions
              txs[hash].lastCheckedBlockNumber = lastBlockNumber
              WalletDispatch({
                type: 'UPDATE',
                payload: {
                  transactions: txs
                }
              })
              txHashChecking.current = ''
            }
          })
          .catch((error: any) => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [library, transactions, lastBlockNumber, showSpinner, addAppNotification, queryClient])
  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || chainId !== Number(DEFAULT_CHAIN_ID)) return undefined

    try {
      library
        .getBlockNumber()
        .then(blockNumberCallback)
        .catch((error: any) => console.error(`Failed to get block number for chainId: ${chainId}`, error))

      library.on('block', blockNumberCallback)
    } catch (e) {
      console.log('e', e)
    }
    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [chainId, library, blockNumberCallback, connector])

  useEffect(() => {
    WalletDispatch({
      type: 'UPDATE',
      payload: {
        selectedWalletType
      }
    })
  }, [])

  if (!chainId) return null

  return (
    <WalletContext.Provider
      value={{
        ...WalletState,
        WalletDispatch,
        addTransaction: addTransaction
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletContextProvider
