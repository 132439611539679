import { SupportedChainId } from './chains'

const NETWORK_URL = process.env.NEXT_PUBLIC_NETWORK_URL
if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`NEXT_PUBLIC_NETWORK_URL must be a defined environment variable`)
}

/**
 * Fallback JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const FALLBACK_URLS: { [key in SupportedChainId]: string[] } = {
  [SupportedChainId.MERLIN]: [
    // "Fallback" URLs
  ],
  [SupportedChainId.MERLIN_TESTNET]: [
    // "Fallback" URLs
  ]
}

/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS: { [key in SupportedChainId]: string[] } = {
  [SupportedChainId.MERLIN]: [NETWORK_URL, ...FALLBACK_URLS[SupportedChainId.MERLIN]],
  [SupportedChainId.MERLIN_TESTNET]: [NETWORK_URL, ...FALLBACK_URLS[SupportedChainId.MERLIN_TESTNET]]
}
