import { ENDPOINTS } from 'constants/index'

import { IPermalink, IRoutes } from './types'

export const permalink: IPermalink = {
  home: '/',
  dashboard: '/account',
  downPayment: '/down-payment',
  batchBorrow: '/account/my-nfts',
  nftCustody: '/nft-custody',
  nftDashboardCustody: '/account/nft-custody',
  nftCustodyDeposit: '/nft-custody/deposit',
  nftCustodyWithdraw: '/nft-custody/withdraw',
  auction: '/auctions',
  bendTools: '/bend-tools',
  bid: '/auctions/bid',
  trade: '',
  fairLaunch: '/fair-launch',
  forum: 'https://forum.benddao.xyz/',
  governance: 'https://snapshot.org/#/benddao.eth',
  analytics: '/analytics',
  analyticsCollection: '/analytics/collection',
  reserveNotFound: '/error/reserve-not-found',
  loanNotFound: '/error/loan-not-found',
  somethingWentWrong: '/error/something-went-wrong',
  nftFaucet: '/nft-faucet',
  mySettings: '/account/settings',
  flashClaim: '/flashclaim',
  flashClaimAirdrop: '/flash-claim/airdrop',
  flashClaimTransferBack: '/flash-claim/transfer-back',
  flashClaimTransferBackDooples: '/flash-claim/transfer-back-dooples',
  flashClaimTransferBackEgg: '/flash-claim/transfer-back-egg',
  myNotifications: '/account/notifications',
  dashboardOverview: '/account/dashboard/',
  nfts: '/account/my-nfts',
  nftsv2: '/account/my-nfts',
  dashboardBorrowsHistory: '/account/history/borrow',
  dashboardRepaysHistory: '/account/history/repay',
  dashboardAuctions: '/account/auctions',
  dashboardSettings: '/account/settings',
  transferBack: '/flash-claim/transfer-back',
  auctionLoansInAuction: '/auctions/loans-in-auction',
  auctionAvailableToLiquidate: '/auctions/available-to-liquidate',
  auctionAvailableToAuction: '/auctions/available-to-auction',
  auctionHealthFactorAlertList: '/auctions/health-factor-alert-list',
  auctionHistory: '/auctions/auction-history',
  batchRepay: '/account/my-nfts?action=batch-repay',
  downpayment: '/liquidity/buy/down-payment',
  liquidityExplore: '/liquidity/buy-nfts',
  liquidityAsset: '/asset',
  asset: '/asset',
  liquidityListAsset: '/liquidity/list-asset',
  liquidityBuyAsset: '/liquidity/buy',
  liquidity: '/liquidity',
  yielding: '/yielding',
  liquidityCollateralListing: '/liquidity/sell-nfts',
  marketplaceActivity: '/liquidity/activity',
  offer: '/asset/offer',
  adjustOffer: '/asset/adjust-offer',
  cancelOffer: '/asset/cancel-offer',
  acceptOffer: '/asset/accept-offer',
  myOffers: '/account/offers',
  ecosystem: '/pages/ecosystem',
  myNfts: '/account/my-nfts?type_in=original',
  myBorrows: '/account/my-nfts?type_in=in_loan',
  accountDanglingOrders: '/account/inactive-orders',
  bendApeStaking: '/ape-staking/collateral-staking/',
  bendApeStakingStakings: '/ape-staking/collateral-staking/stakings',
  bendApeStakingMyNFTs: '/ape-staking/collateral-staking/apes',
  bendApeStakingMyPairingListings: '/ape-staking/collateral-staking/pairing-listings',
  bendApeStakingExplorePairingListings: '/ape-staking/collateral-staking/explore/pairing-listings',
  bendApeStakingExploreActiveStakings: '/ape-staking/collateral-staking/explore/active-stakings',
  bendApeStakingMyStakings: '/ape-staking/collateral-staking/stakings/paired-stakings',
  bendApeStakingMyPairedStakings: '/ape-staking/collateral-staking/stakings/paired-stakings',
  docs: 'https://docs.benddao.xyz/portal/',
  bendApeStakingHistory: '/ape-staking/collateral-staking/history',
  collection: '/collection',
  yieldHub: '/yield/deposit',
  yieldDeposit: '/yield/deposit/btc',
  yieldDepositUsdt: '/yield/deposit/usdt',
  yieldStakeBend: '/yield/get-vebend',
  yieldLps: '/yield/liquidity-mining',
  nftWrappers: '/bend-tools/nft-wrappers',
  flashClaimProjects: '/bend-tools/flashclaim-projects',
  flashClaimUserProxy: '/bend-tools/userproxy',
  bendApeStakingCompounder: '/ape-staking/collateral-staking/stakings/reward-auto-compound',
  repayHistory: '/account/history/repay',
  bluechipConsensus: 'https://docs.benddao.xyz/portal/risk/bluechip-nft-eligibility-requirements',
  stakingOverview: '/ape-staking/pool-staking/my-stakings',
  stakingStatistics: '/ape-staking/pool-staking/statistics',
  migrateStaking: '/ape-staking/pool-staking/migrate',
  governanceMechanism: 'https://docs.benddao.xyz/portal/governance/governance-mechanism',
  stakingActivityLog: '/ape-staking/pool-staking/activity-log',
  stakingPools: '/ape-staking/pool-staking/staking-pools',
  staking: '/ape-staking',
  soloStaking: '/ape-staking/solo-staking',
  dashboardHistory: '/account/history/',
  nativeSwap: '/bend-tools/native-swap',
  dune: 'https://dune.com/benddao/benddao',
  bdinWhitelistCheck: '/bdin/whitelist-check',
  bdinPage: '/bdin',
  delegateWalletV1: '/bend-tools/delegate-wallet/v1',
  delegateWalletV2: '/bend-tools/delegate-wallet/v2'
}

export const auctionSidebarRoute: IRoutes = {
  type: 'nav-item',
  name: 'Auction',
  icon: 'IconAuction',
  route: permalink.auction,
  auth: false,
  external: false,
  children: [
    {
      type: 'nav-item',
      name: 'Available to Auction',
      icon: 'IconActiveAuctions2',
      route: permalink.auctionAvailableToAuction,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      name: 'Loans in Auction',
      icon: 'IconAuction',
      route: permalink.auctionLoansInAuction,
      auth: false,
      external: false
    }
  ]
}

export const dashboardRoutes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Dashboard',
    icon: 'IconGridList',
    route: permalink.dashboardOverview
  },
  {
    type: 'nav-item',
    name: 'My NFTs',
    icon: 'IconBNFT',
    route: permalink.nfts,
    children: [
      {
        type: 'nav-item',
        name: 'Borrow History',
        icon: '',
        route: permalink.dashboardBorrowsHistory
      },
      {
        type: 'nav-item',
        name: 'Repay History',
        icon: '',
        route: permalink.repayHistory
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Active Bids',
    icon: 'IconMyAuctions',
    route: permalink.dashboardAuctions
  },
  /*   {
    type: 'nav-item',
    name: 'Offers',
    icon: 'IconOffer',
    route: permalink.myOffers
  }, */
  /*   {
    type: 'nav-item',
    name: 'Inactive Orders',
    icon: 'IconDandlingCancel',
    route: permalink.accountDanglingOrders
  },
  {
    type: 'nav-item',
    name: 'Custody',
    icon: 'IconNftVault',
    route: permalink.nftDashboardCustody
  }, */
  {
    type: 'nav-item',
    name: 'Settings',
    icon: 'IconSettings',
    route: permalink.dashboardSettings
  }
]

export const accountRoute: IRoutes = {
  type: 'nav-item',
  name: 'Account',
  icon: 'IconDashboard',
  route: permalink.dashboard,
  external: false,
  children: [
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Dashboard',
      icon: 'IconGridList',
      route: permalink.dashboardOverview,
      auth: false,
      external: false,
      onlyMobile: true
    },
    {
      type: 'separator',
      parent: 'Dashboard',
      name: 'dash-separator-1',
      icon: '',
      route: ''
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'My NFTs',
      icon: 'IconBNFT',
      route: permalink.nfts,
      auth: false,
      external: false
    },
    // {
    //   type: 'nav-item',
    //   parent: 'Dashboard',
    //   name: 'Borrows',
    //   icon: 'IconBorrow',
    //   route: permalink.myBorrows,
    //   auth: false,
    //   external: false
    // },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Rewards',
      icon: 'IconClaimRewards',
      route: `${permalink.dashboardOverview}#yield-and-rewards`,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Active bids',
      icon: 'IconMyAuctions',
      route: permalink.dashboardAuctions,
      auth: false,
      external: false
    },
    /*     {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Offers',
      icon: 'IconOffer',
      route: permalink.myOffers,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Inactive Orders',
      icon: 'IconDandlingCancel',
      route: permalink.accountDanglingOrders,
      auth: false,
      external: false
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Custody',
      icon: 'IconNftVault',
      route: permalink.nftDashboardCustody,
      auth: false,
      external: false
    }, */
    {
      type: 'separator',
      parent: 'Dashboard',
      name: 'dash-separator-3',
      icon: '',
      route: ''
    },
    {
      type: 'nav-item',
      parent: 'Dashboard',
      name: 'Settings',
      icon: 'IconSettings',
      route: permalink.dashboardSettings,
      auth: false,
      external: false
    }
  ]
}

export const yieldHubRoutes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Deposit',
    icon: 'IconReserve',
    route: permalink.yieldHub,
    children: [
      {
        type: 'nav-item',
        name: 'BTC',
        icon: '',
        route: permalink.yieldDeposit
      },
      {
        type: 'nav-item',
        name: 'USDT',
        icon: '',
        isNew: true,
        route: permalink.yieldDepositUsdt
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Get veBEND',
    icon: 'IconStake',
    route: permalink.yieldStakeBend
  },
  {
    type: 'nav-item',
    name: 'Liquidity mining',
    icon: 'IconYieldHub',
    route: permalink.yieldLps
  }
]

export const flashClaimRoutes: IRoutes[] = [
  /*     {
    type: 'nav-item',
    name: 'Asset Management',
    icon: 'IconWallet',
    route: permalink.flashClaimAssetManagement
    }, */
  {
    type: 'nav-item',
    name: 'Delegate Wallet',
    icon: 'IconDelegateNav',
    route: permalink.delegateWalletV2,
    children: [
      {
        type: 'nav-item',
        name: 'Registry v2',
        icon: '',
        route: permalink.delegateWalletV2,
        isNew: true
      },
      {
        type: 'nav-item',
        name: 'Registry v1',
        icon: '',
        route: permalink.delegateWalletV1
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Native Swap',
    icon: 'IconNativeSwap',
    route: permalink.nativeSwap
  },
  {
    type: 'nav-item',
    name: 'Flash Claim Projects',
    icon: 'IconAirdrop',
    route: permalink.flashClaimProjects
  },
  {
    type: 'nav-item',
    name: 'NFT Wrappers',
    icon: 'IconNFTWrappers',
    route: permalink.nftWrappers
  }
]

export const apeCoinStakingRoutes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Explore APEs',
    icon: 'IconExplorePairingListings',
    route: permalink.bendApeStakingExplorePairingListings,
    children: [
      {
        type: 'nav-item',
        name: 'Pairing listings',
        icon: '',
        route: permalink.bendApeStakingExplorePairingListings
      },
      {
        type: 'nav-item',
        name: 'Active stakings',
        icon: '',
        route: permalink.bendApeStakingExploreActiveStakings
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'APEs',
    icon: 'IconBNFT',
    route: permalink.bendApeStakingMyNFTs
  },
  {
    type: 'nav-item',
    name: 'Pairing listings',
    icon: 'IconBendApe',
    route: permalink.bendApeStakingMyPairingListings
  },
  /*   {
    type: 'nav-item',
    name: 'Paired & Solo stakings',
    icon: 'IconMyStakings',
    route: permalink.bendApeStakingMyStakings
  }, */
  {
    type: 'nav-item',
    name: 'Stakings',
    icon: 'IconMyStakings',
    route: permalink.bendApeStakingMyStakings,
    children: [
      {
        type: 'nav-item',
        name: 'Paired stakings',
        icon: '',
        route: permalink.bendApeStakingMyStakings
      },
      {
        type: 'nav-item',
        parent: 'Stakings',
        name: 'Reward compound',
        icon: '',
        route: permalink.bendApeStakingCompounder
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Migrate to Pool staking',
    icon: 'IconMigrateStaking',
    route: permalink.migrateStaking
  }
]

export const stakingRoutes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Staking pools',
    icon: 'IconMyStakings',
    route: permalink.stakingPools
  },
  {
    type: 'nav-item',
    name: 'My stakings',
    icon: 'IconCompounder',
    route: permalink.stakingOverview
  },
  {
    type: 'nav-item',
    name: 'Activity log',
    icon: 'IconActivityLog',
    route: permalink.stakingActivityLog
  },
  {
    type: 'nav-item',
    name: 'Migrate to Pool staking',
    icon: 'IconMigrateStaking',
    route: permalink.migrateStaking
  },
  {
    type: 'nav-item',
    name: 'Collateral Staking',
    icon: 'IconCollateralStaking',
    route: permalink.bendApeStakingExplorePairingListings
  },
  {
    type: 'nav-item',
    name: 'Solo Staking',
    icon: 'IconSoloStaking',
    route: permalink.soloStaking
  }
]

export const soloStakingRoutes: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Solo staking',
    icon: 'IconSoloStaking',
    route: permalink.soloStaking
  }
]

export default () => ENDPOINTS[process.env.NEXT_PUBLIC_CHAIN_ID || '1'].ROUTES
