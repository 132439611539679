import { ChainId } from 'wallet-module'

import AIRDROP_DISTRIBUTION_CONTRACT_ABI from './abis/AirdropDistribution.json'
import BEND_COMPETITION_ABI from './abis/BendCompetition.json'
import BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI from './abis/BendProtocolIncentivesController.json'
import BNFT_ABI from './abis/BNFT.json'
import BOUND_PUNK_GATEWAY_ABI from './abis/BoundPunkGateway.json'
import CRYPTO_PUNKS_MARKET_ABI from './abis/CryptoPunksMarket.json'
import DEBT_TOKEN_ABI from './abis/DebtToken.json'
import DELEGATE_CASH_ABI from './abis/DelegateCash.json'
import EIP2981_ABI from './abis/EIP2981.json'
import ENS_PUBLIC_RESOLVER_ABI from './abis/ens-public-resolver.json'
import ENS_ABI from './abis/ens-registrar.json'
import FEE_DISTRIBUTOR_ABI from './abis/FeeDistributor.json'
import ERC721_ABI from './abis/IERC721Detailed.json'
import LEND_POOL_ABI from './abis/ILendPool.json'
import PUNK_GATEWAY_ABI from './abis/IPunkGateway.json'
import WBTC_GATEWAY_ABI from './abis/IWBTCGateway.json'
import WETH_GATEWAY_ABI from './abis/IWETHGateway.json'
import WRAPPED_PUNKS_ABI from './abis/IWrappedPunks.json'
import KODA_WRAPPER_ABI from './abis/KodaWrapper.json'
import LEND_POOL_ADDRESS_PROVIDER_ABI from './abis/LendPoolAddressesProvider.json'
import LEND_POOL_LOAN_ABI from './abis/LendPoolLoan.json'
import LEND_POOL_SWAP_ABI from './abis/LendPoolSwap.json'
import MERKLE_DISTRIBUTOR_ABI from './abis/MerkleDistributor.json'
import MULTI_CALL_NONPAYABLE_ABI from './abis/MulticallNonpayable.json'
import BEND_STAKE_ABI from './abis/StakedBUNI.json'
import UI_POOL_DATA_PROVIDER_ABI from './abis/UiPoolDataProvider.json'
import UNISWAP_PAIR_ABI from './abis/UniswapV2Pair.json'
import VE_BEND_ABI from './abis/VeBend.json'
import WETH_ABI from './abis/Weth.json'

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '686868'

const ADDRESSES: any = {
  [ChainId.MERLIN_TESTNET]: {
    FEE_DISTRIBUTOR_ADDRESS: '',
    VE_BEND_ADDRESS: '',
    MERKLE_DISTRIBUTOR_ADDRESS: '',
    LEND_POOL_ADDRESSES_PROVIDER: '0x6DB1D8d1F5C9c694a39Ee080905b4ec2AE0B061A',
    LEND_POOL: '0x18754245fFDE937e7E66CdCB033Bd1B7664e6855',
    LEND_POOL_LOAN: '0x53e52eAF0aA015761905483495B9fF4ae23824A2',
    UI_POOL_DATA_PROVIDER: '0x377E55c76d7406CAC2528304FE952F23Bd97376A',
    WBTC_GATEWAY_ADDRESS: '0x8E986fCfe2Adc2FEfe6C3Ec3a568544f170A34eA',
    WBTC_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '',
    USDT_ADDRESS: '',
    BEND_ADDRESS: '',
    CRYPTOPUNKS_ADDRESS: '',
    WPUNKS_ADDRESS: '',
    BWBTC: '',
    BDebtWETH: '',
    BUSDT: '',
    BDebtUSDT: '',
    BEND_COMPETITION: '',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '',
    BEND_ETH_UNISWAP_POOL: '',
    BEND_COLLECTOR: '',
    BOUND_PUNK_GATEWAY: '',
    AIRDROP_DISTRIBUTION_CONTRACT: '',
    BEND_STAKE: '',
    BEND_EXCHANGE: '',
    AUTHORIZATION_MANAGER: '',
    REDEEM_NFT: '',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '',
    ROYALTY_FEE_MANAGER: '',
    DOWNPAYMENT: '',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '',
    DOWNPAYMENT_PUNK_ADAPTER: '',
    DOWNPAYMENT_SEAPORT_ADAPTER: '',
    DOWNPAYMENT_X2Y2_ADAPTER: '',
    BEND_APE_STAKING_ADDRESS: '',
    STAKE_MANAGER_ADDRESS: '',
    APECOIN_ADDRESS: '',
    APECOIN_ADDRESS_MAINNET: '',
    BAYC_ADDRESS: '',
    MAYC_ADDRESS: '',
    BAKC_ADDRESS: '',
    APECOIN_STAKING_ADDRESS: '',
    BEND_APECOIN_ADDRESS: '',
    OTHERDEED_ADDRESS: '',
    WKODA_ADDRESS: '',
    KODA_GATEWAY: '',
    APE_ETH_UNISWAP_POOL: '',
    DELEGATE_CASH_ADDRESS: '',
    LEND_POOL_SWAP_ADDRESS: '',
    NATIVE_ORG_API_KEY: '',
    WBTC_ADDRESS: '0x4D4A7910B55E815EBb17a15725F440766EcF934d',
    WSTETH_ADDRESS: ''
  },
  [ChainId.MERLIN]: {
    FEE_DISTRIBUTOR_ADDRESS: '',
    VE_BEND_ADDRESS: '',
    MERKLE_DISTRIBUTOR_ADDRESS: '',
    LEND_POOL_ADDRESSES_PROVIDER: '0x7803d860813f93cC0626487C255E78676D73bE62',
    LEND_POOL: '0xb7C4Ecdc6346B23baa0dfb0B75984C37afCCbD91',
    LEND_POOL_LOAN: '0xa3C1951661f209F65c84aDF4A0e40fF9fFa25797',
    UI_POOL_DATA_PROVIDER: '0xA4256bBb6467Df2fCcE8277ed282A1470ED5b6d5',
    WBTC_GATEWAY_ADDRESS: '0x9aF0d9e8335fbDa7A4Ecf3f46c066b33776fd636',
    WBTC_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '',
    WETH_ADDRESS: '',
    USDT_ADDRESS: '',
    BEND_ADDRESS: '',
    CRYPTOPUNKS_ADDRESS: '',
    WPUNKS_ADDRESS: '',
    BWBTC: '',
    BDebtWETH: '',
    BUSDT: '',
    BDebtUSDT: '',
    BEND_COMPETITION: '',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '',
    BEND_ETH_UNISWAP_POOL: '',
    BEND_COLLECTOR: '',
    BOUND_PUNK_GATEWAY: '',
    AIRDROP_DISTRIBUTION_CONTRACT: '',
    BEND_STAKE: '',
    BEND_EXCHANGE: '',
    AUTHORIZATION_MANAGER: '',
    REDEEM_NFT: '',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '',
    ROYALTY_FEE_MANAGER: '',
    DOWNPAYMENT: '',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '',
    DOWNPAYMENT_PUNK_ADAPTER: '',
    DOWNPAYMENT_SEAPORT_ADAPTER: '',
    DOWNPAYMENT_X2Y2_ADAPTER: '',
    BEND_APE_STAKING_ADDRESS: '',
    STAKE_MANAGER_ADDRESS: '',
    APECOIN_ADDRESS: '',
    APECOIN_ADDRESS_MAINNET: '',
    BAYC_ADDRESS: '',
    MAYC_ADDRESS: '',
    BAKC_ADDRESS: '',
    APECOIN_STAKING_ADDRESS: '',
    BEND_APECOIN_ADDRESS: '',
    OTHERDEED_ADDRESS: '',
    WKODA_ADDRESS: '',
    KODA_GATEWAY: '',
    APE_ETH_UNISWAP_POOL: '',
    DELEGATE_CASH_ADDRESS: '',
    LEND_POOL_SWAP_ADDRESS: '',
    NATIVE_ORG_API_KEY: '',
    WBTC_ADDRESS: '0xF6D226f9Dc15d9bB51182815b320D3fBE324e1bA',
    WSTETH_ADDRESS: ''
  }
}

export const OTHERDEED_ADDRESS = process.env.NEXT_PUBLIC_OTHERDEED_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].OTHERDEED_ADDRESS
export const WKODA_ADDRESS = process.env.NEXT_PUBLIC_WKODA_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WKODA_ADDRESS
export const KODA_GATEWAY = process.env.NEXT_PUBLIC_KODA_GATEWAY || ADDRESSES[DEFAULT_CHAIN_ID].KODA_GATEWAY

export const BEND_APECOIN_ADDRESS = process.env.NEXT_PUBLIC_BEND_APECOIN_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_APECOIN_ADDRESS
export const APECOIN_STAKING_ADDRESS = process.env.NEXT_PUBLIC_APECOIN_STAKING_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_STAKING_ADDRESS
export const BEND_APE_STAKING_ADDRESS = process.env.NEXT_PUBLIC_BEND_APE_STAKING_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_APE_STAKING_ADDRESS
export const STAKE_MANAGER_ADDRESS = process.env.NEXT_PUBLIC_STAKE_MANAGER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKE_MANAGER_ADDRESS
export const APECOIN_ADDRESS = process.env.NEXT_PUBLIC_APECOIN_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_ADDRESS
export const APECOIN_ADDRESS_MAINNET = process.env.NEXT_PUBLIC_APECOIN_ADDRESS_MAINNET || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_ADDRESS_MAINNET
export const BAYC_ADDRESS = process.env.NEXT_PUBLIC_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BAYC_ADDRESS
export const MAYC_ADDRESS = process.env.NEXT_PUBLIC_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].MAYC_ADDRESS
export const BAKC_ADDRESS = process.env.NEXT_PUBLIC_BAKC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BAKC_ADDRESS
export const APECOIN_TESTNET_FAUCET = '0x478e5eDFb12e2e59F6BBC777A73761e4E2DeA0ba'

export const BEND_EXCHANGE = ADDRESSES[DEFAULT_CHAIN_ID].BEND_EXCHANGE
export const AUTHORIZATION_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].AUTHORIZATION_MANAGER
export const REDEEM_NFT = ADDRESSES[DEFAULT_CHAIN_ID].REDEEM_NFT
export const STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE = ADDRESSES[DEFAULT_CHAIN_ID].STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE
export const ROYALTY_FEE_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].ROYALTY_FEE_MANAGER

export const DOWNPAYMENT = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT
export const DOWNPAYMENT_BEND_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_BEND_EXCHANGE_ADAPTER
export const DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER
export const DOWNPAYMENT_PUNK_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_PUNK_ADAPTER
export const DOWNPAYMENT_SEAPORT_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_SEAPORT_ADAPTER
export const DOWNPAYMENT_X2Y2_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_X2Y2_ADAPTER

export const FEE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_FEE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].FEE_DISTRIBUTOR_ADDRESS
export const VE_BEND_ADDRESS = process.env.NEXT_PUBLIC_VE_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].VE_BEND_ADDRESS

export const MERKLE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_MERKLE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].MERKLE_DISTRIBUTOR_ADDRESS
export const LEND_POOL_ADDRESSES_PROVIDER = process.env.NEXT_PUBLIC_LEND_POOL_ADDRESSES_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_ADDRESSES_PROVIDER
export const LEND_POOL = process.env.NEXT_PUBLIC_LEND_POOL || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL
export const LEND_POOL_LOAN = process.env.NEXT_PUBLIC_LEND_POOL_LOAN || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_LOAN
export const UI_POOL_DATA_PROVIDER = process.env.NEXT_PUBLIC_UI_POOL_DATA_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].UI_POOL_DATA_PROVIDER

export const WBTC_GATEWAY_ADDRESS = process.env.NEXT_PUBLIC_WBTC_GATEWAY_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WBTC_GATEWAY_ADDRESS
export const WBTC_GATEWAY_ID = process.env.NEXT_PUBLIC_WBTC_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].WBTC_GATEWAY_ID
export const PUNK_GATEWAY_ID = process.env.NEXT_PUBLIC_PUNK_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].PUNK_GATEWAY_ID

export const WETH_ADDRESS = process.env.NEXT_PUBLIC_WETH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WETH_ADDRESS
export const MERLIN_TESTNET_WBTC_ADDRESS = ADDRESSES[ChainId.MERLIN_TESTNET].WBTC_ADDRESS
export const MERLIN_WBTC_ADDRESS = ADDRESSES[ChainId.MERLIN].WBTC_ADDRESS
export const USDT_ADDRESS = process.env.NEXT_PUBLIC_USDT_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].USDT_ADDRESS
export const BEND_ADDRESS = process.env.NEXT_PUBLIC_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ADDRESS
export const CRYPTOPUNKS_ADDRESS = process.env.NEXT_PUBLIC_CRYPTOPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].CRYPTOPUNKS_ADDRESS
export const WPUNKS_ADDRESS = process.env.NEXT_PUBLIC_WPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WPUNKS_ADDRESS

export const BWETH = process.env.NEXT_PUBLIC_BWETH || ADDRESSES[DEFAULT_CHAIN_ID].BWETH
export const BWBTC = process.env.NEXT_PUBLIC_BWBTC || ADDRESSES[DEFAULT_CHAIN_ID].BWBTC
export const BDebtWETH = process.env.NEXT_PUBLIC_BDebtWETH || ADDRESSES[DEFAULT_CHAIN_ID].BDebtWETH

export const BUSDT = process.env.NEXT_PUBLIC_BUSDT || ADDRESSES[DEFAULT_CHAIN_ID].BUSDT
export const BDebtUSDT = process.env.NEXT_PUBLIC_BDebtUSDT || ADDRESSES[DEFAULT_CHAIN_ID].BDebtUSDT

export const WBTC_ADDRESS = process.env.NEXT_PUBLIC_WBTC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WBTC_ADDRESS
export const WSTETH_ADDRESS = process.env.NEXT_PUBLIC_WSTETH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WSTETH_ADDRESS

export const BEND_COMPETITION = process.env.NEXT_PUBLIC_BEND_COMPETITION || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COMPETITION
export const BEND_PROTOCOL_INCENTIVES_CONTROLLER =
  process.env.NEXT_PUBLIC_INCENTIVE_CONTROLLER || ADDRESSES[DEFAULT_CHAIN_ID].BEND_PROTOCOL_INCENTIVES_CONTROLLER

export const BEND_ETH_UNISWAP_POOL = process.env.NEXT_PUBLIC_BEND_ETH_UNISWAP_POOL || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ETH_UNISWAP_POOL
export const BEND_COLLECTOR = process.env.NEXT_PUBLIC_BEND_COLLECTOR || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COLLECTOR
export const BOUND_PUNK_GATEWAY = process.env.NEXT_PUBLIC_BOUND_PUNK_GATEWAY || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_PUNK_GATEWAY

export const AIRDROP_DISTRIBUTION_CONTRACT = process.env.NEXT_PUBLIC_AIRDROP_DISTRIBUTION_CONTRACT || ADDRESSES[DEFAULT_CHAIN_ID].AIRDROP_DISTRIBUTION_CONTRACT

export const BEND_STAKE = process.env.NEXT_PUBLIC_BEND_STAKE || ADDRESSES[DEFAULT_CHAIN_ID].BEND_STAKE

export const DELEGATE_CASH_ADDRESS = process.env.NEXT_PUBLIC_DELEGATE_CASH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].DELEGATE_CASH_ADDRESS

export const LEND_POOL_SWAP_ADDRESS = process.env.NEXT_PUBLIC_LEND_POOL_SWAP_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_SWAP_ADDRESS
export const NATIVE_ORG_API_KEY = process.env.NEXT_PUBLIC_NATIVE_ORG_API_KEY || ADDRESSES[DEFAULT_CHAIN_ID].NATIVE_ORG_API_KEY

export const MAX_BORROW_MULTIPLIER = 0.99

export const ENS_REGISTRAR_ADDRESSES = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'

export const UNISWAP_FEE = 0.003
export const LENDPOOL_SWAP_SLIPPAGE = 100
export const LENDPOOL_UNISWAP_FEE = 3000 // 1% == 10000, 0.3% == 3000, 0.01% == 100

export const DEFAULT_ADDED_GAS_CALC_PERCENT = 20
export const RECOMMENDED_MIN_GAS_LIMIT = {
  LendPool: {
    deposit: 300000,
    withdraw: 200000,
    borrow: 1000000,
    repay: 700000,
    auction: 500000,
    redeem: 500000,
    liquidate: 700000
  },
  WETHGateway: {
    depositETH: 350000,
    withdrawETH: 400000,
    borrowETH: 1000000,
    repayETH: 700000,
    auctionETH: 550000,
    redeemETH: 550000,
    liquidateETH: 700000
  },
  WBTCGateway: {
    depositBTC: 350000,
    withdrawBTC: 400000,
    borrowBTC: 1000000,
    repayBTC: 700000,
    auctionBTC: 550000,
    redeemBTC: 550000,
    liquidateBTC: 700000
  },
  PunkGateway: {
    borrowETH: 1200000,
    repayETH: 900000,
    auctionETH: 650000,
    redeemETH: 650000,
    liquidateETH: 900000
  }
}

export const maxBendSupply = '10000000000000000000000000000' // in wei

export {
  AIRDROP_DISTRIBUTION_CONTRACT_ABI,
  BEND_COMPETITION_ABI,
  BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI,
  BEND_STAKE_ABI,
  BNFT_ABI,
  BOUND_PUNK_GATEWAY_ABI,
  CRYPTO_PUNKS_MARKET_ABI,
  DEBT_TOKEN_ABI,
  DELEGATE_CASH_ABI,
  EIP2981_ABI,
  ENS_ABI,
  ENS_PUBLIC_RESOLVER_ABI,
  ERC721_ABI,
  FEE_DISTRIBUTOR_ABI,
  KODA_WRAPPER_ABI,
  LEND_POOL_ABI,
  LEND_POOL_ADDRESS_PROVIDER_ABI,
  LEND_POOL_LOAN_ABI,
  LEND_POOL_SWAP_ABI,
  MERKLE_DISTRIBUTOR_ABI,
  MULTI_CALL_NONPAYABLE_ABI,
  PUNK_GATEWAY_ABI,
  UI_POOL_DATA_PROVIDER_ABI,
  UNISWAP_PAIR_ABI,
  VE_BEND_ABI,
  WBTC_GATEWAY_ABI,
  WETH_ABI,
  WETH_GATEWAY_ABI,
  WRAPPED_PUNKS_ABI
}
