import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useWalletDisplay, WalletSteps } from 'modules/bend/hooks/useWallet'
import { Flex } from 'rebass/styled-components'
import { getConnection } from 'wallet-module'
import useBtcWallet from 'wallet-module/hooks/useBtcWallet'

import { EthWalletStep1 } from './wallet-steps/step1-eth'
import { EthWalletStep2 } from './wallet-steps/step2-eth'
import { EthWalletStep3 } from './wallet-steps/step3-eth'

export const WalletMenu: React.FC = () => {
  const { account, chainId } = useWeb3React()
  const {
    errorMsg,
    screenState,
    setScreenState,
    connector,
    tryActivation,
    pendingError,
    setPendingError,
    pendingErrorWrongNetwork,
    setPendingWrongNetwork,
    pendingWallet
  } = useWalletDisplay()
  const handleShowChangeProvider = useCallback(() => {
    if (getConnection(connector)?.type !== 'NETWORK') {
      if (connector.deactivate) {
        connector.deactivate()
      } else {
        connector.resetState()
      }
    }
    setScreenState(WalletSteps.SELECT_PROVIDER)
  }, [connector, setScreenState])

  const handleSelectProviderBack = useCallback(() => {
    setScreenState(WalletSteps.CONNECTED)
  }, [setScreenState])

  useEffect(() => {
    if (chainId !== Number(DEFAULT_CHAIN_ID)) {
      setScreenState(WalletSteps.PENDING)
    }
  }, [chainId, setScreenState])

  const [, setBtcScreenState] = useState<WalletSteps>(WalletSteps.SELECT_PROVIDER)
  const { unisatInstalled, okxInstalled, connected } = useBtcWallet()

  useEffect(() => {
    if ((unisatInstalled && connected) || (okxInstalled && connected)) {
      setBtcScreenState(WalletSteps.CONNECTED)
    } else {
      setBtcScreenState(WalletSteps.SELECT_PROVIDER)
    }
  }, [unisatInstalled, connected, okxInstalled])

  return (
    <>
      <Flex flexDirection='column'>
        {(() => {
          switch (screenState) {
            case WalletSteps.PENDING:
              return (
                <EthWalletStep3
                  connector={pendingWallet}
                  tryActivation={tryActivation}
                  error={pendingError}
                  setPendingError={setPendingError}
                  pendingErrorWrongNetwork={pendingErrorWrongNetwork}
                  setPendingWrongNetwork={setPendingWrongNetwork}
                  handleBack={handleShowChangeProvider}
                  errorMsg={errorMsg}
                  setScreenState={setScreenState}
                />
              )
            case WalletSteps.SELECT_PROVIDER:
              return <EthWalletStep2 handleBack={handleSelectProviderBack} tryActivation={tryActivation} account={account} connector={connector} />

            default:
            case WalletSteps.CONNECTED:
              return <EthWalletStep1 account={account} connector={connector} handleShowChangeProvider={handleShowChangeProvider} />
          }
        })()}
      </Flex>
      {/*       <Box mx={-40}>
        <SpacerDash height={1} width='100%' bgColor={colors.grey[5]} my={20} />
      </Box>
      <Flex flexDirection='column'>
        {(() => {
          switch (btcScreenState) {
            case WalletSteps.SELECT_PROVIDER:
              return <BtcWalletStep2 />
            default:
            case WalletSteps.CONNECTED:
              return <BtcWalletStep1 />
          }
        })()}
      </Flex> */}
    </>
  )
}
