import { useState } from 'react'
import { Menu } from 'react-feather'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { Web3Status } from 'modules/wallet'
import NetworkSelect from 'modules/wallet/components/network-select'
import { useCheckAccount } from 'modules/wallet-module/hooks/useCheckAccount'
import { Box, Flex, Image } from 'rebass/styled-components'
import styled, { keyframes } from 'styled-components'
import { AnimatePresence, Button } from 'theme/ui'

import Link from 'components/common/link'

import MobileNavigationSidebar from '../mobile-navigation-sidebar'

import Navigation from './navigation'

const homeLogoAnimOut = keyframes`
    0% {
      -webkit-filter: grayscale(0%) brightness(100%);
      -moz-filter: grayscale(0%) brightness(100%);
      -o-filter: grayscale(0%) brightness(100%);
      filter: grayscale(0%) brightness(100%);
    }
    100% {
      -webkit-filter: grayscale(100%) brightness(0%);
      -moz-filter: grayscale(100%) brightness(0%);
      -o-filter: grayscale(100%) brightness(0%);
      filter: grayscale(100%) brightness(0%);
    }
  `
const homeLogoAnimIn = keyframes`
    0% {
      -webkit-filter: grayscale(100%) brightness(0%);
      -moz-filter: grayscale(100%) brightness(0%);
      -o-filter: grayscale(100%) brightness(0%);
      filter: grayscale(100%) brightness(0%);
    }
    100% {
      -webkit-filter: grayscale(0%) brightness(100%);
      -moz-filter: grayscale(0%) brightness(100%);
      -o-filter: grayscale(0%) brightness(100%);
      filter: grayscale(0%) brightness(100%);
    }
  `

const StyledFlex = styled(Flex)`
  cursor: pointer;
  img {
    animation: ${homeLogoAnimOut} 0.6s forwards;
  }
  &:hover img {
    animation: ${homeLogoAnimIn} 0.6s forwards;
  }
`

export const HEADER_HEIGHT = 78
export const WINDOW_POSITION_TRIGGER = 0

const Header: React.FC = () => {
  const { colors } = useTheme()
  const { isDesktop } = useResponsive()
  const [showMobileMenu, toggleMobileMenu] = useState(false)

  useCheckAccount()

  // const windowPosition = useWindowPosition()
  // const [show, setShow] = useState(true)
  // const navbarRef = useRef<number>(0)

  // useEffect(() => {
  //   if (windowPosition < WINDOW_POSITION_TRIGGER) return
  //   if (navbarRef.current < windowPosition) {
  //     setShow(false)
  //   } else {
  //     setShow(true)
  //   }
  //   navbarRef.current = windowPosition
  // }, [windowPosition])

  return (
    <>
      <MobileNavigationSidebar showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />
      <AnimatePresence>
        <Flex
          // initial={{
          //   opacity: 1,
          //   // y: 0,
          //   height: HEADER_HEIGHT
          // }}
          // animate={{
          //   opacity: show ? 1 : 0,
          //   y: show ? 0 : -HEADER_HEIGHT,
          //   height: windowPosition > WINDOW_POSITION_TRIGGER ? HEADER_HEIGHT - 20 : HEADER_HEIGHT
          // }}
          // transition={{
          //   type: 'tween',
          //   duration: 0.3
          // }}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            py: [22, 22, 22, 22, 0],
            // bg: windowPosition > WINDOW_POSITION_TRIGGER ? rgba(255, 255, 255, 1) : undefined,
            // boxShadow: windowPosition > WINDOW_POSITION_TRIGGER ? '0 -8px 16px 0 rgba(0,0,0,0.08)' : undefined,
            bg: 'white',
            boxShadow: `0px 0px 12px 0px ${colors.shadow[200]}`,
            transition: 'all 0.1s ease-in-out',
            zIndex: 302,
            height: HEADER_HEIGHT - 20
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              px: [20]
            }}
          >
            {!isDesktop ? (
              <Flex alignItems='center'>
                <Box minWidth={[24]} mt={8}>
                  <Button
                    type='button'
                    size='icon'
                    backgroundColor='transparent'
                    fontColor='black'
                    width={24}
                    height={24}
                    style={{ borderColor: 'transparent' }}
                    mr={6}
                    onClick={() => toggleMobileMenu(state => !state)}
                  >
                    <Menu size={24} />
                  </Button>
                </Box>
                <Link href='/' passHref>
                  <Flex as='a' sx={{ ml: 4 }}>
                    <Image src='/images/bdin-logo-black.png' width={[180, 180, 210]} />
                  </Flex>
                </Link>
              </Flex>
            ) : (
              <Flex alignItems='center' mt={8}>
                <StyledFlex>
                  <Link href='/' passHref>
                    <Flex as='a'>
                      <Box sx={{ mr: 12 }}>
                        <Image src='/images/bdin-logo-black.png' width={[180, 180, 210]} />
                      </Box>
                    </Flex>
                  </Link>
                </StyledFlex>
              </Flex>
            )}

            {isDesktop && <Navigation />}
            <Flex alignItems='center'>
              <NetworkSelect border />
              <Web3Status />
            </Flex>
          </Flex>
        </Flex>
      </AnimatePresence>
    </>
  )
}

export default Header

export { Navigation }
