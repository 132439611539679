// import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react'
import { useMutation } from 'react-query'
import { FetchResult, gql } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { clientNftApi } from 'clients'
// import { LS_TOKEN } from 'constants/index'
import { ReducerActions } from 'constants/types'
import { getCookie, removeCookies, setCookies } from 'cookies-next'
import useVerifyModalAddresModal from 'hooks/common/useVerfiyAddressModal'
import decode, { JwtPayload } from 'jwt-decode'
import useSignature from 'modules/bend/hooks/useSignature'
import { checkOldSignature } from 'utils'

type Message = string | undefined

type Telegram = {
  username: string
  userAddres: string
}

type Discord = Telegram

type Newsletter = {
  address: string
  topic: string
  email: string
}

export type User = {
  address: string
  username: string
  email: string
  isEmailVerified: boolean
  twitter: string
  instagram: string
  biography: string
  telegram: Telegram | null
  discord: Discord | null
  referralCode: string
  referral: {
    referrerCode: string
  }
  mailTopicSubscription: Array<Newsletter>
}

export type ConnectToState = 'telegram' | 'discord' | 'epsn' | ''

type UserContextProps = {
  isLoggedIn: boolean | null
  token: string | null
  user: User
  connectTo: ConnectToState
  dispatch?: any
  handleLogin?: any
  validateUsername?: any
  validateUsernameResult?: any
  updateUser?: any
  updateUserEmail?: any
  currentUser?: any
  telegramConnect?: any
  telegramDisconnect?: any
  discordConnect?: any
  discordDisconnect?: any
  sendEmailVerification?: any
  createReferral?: any
  handleLoginAndCreateReferral?: any
  handleVerifyModal?: any
  isUserSubscribedMailTopic?: any
  refreshToken?: any
}

type State = {
  isLoggedIn: boolean | null
  token: null | string
  user: User
  connectTo: ConnectToState
}

type LoginProps = {
  message: Message
  signature: string | undefined
  address: string | undefined
}

export type UpdateUserProps = {
  username: string
  email: string
  twitter: string
  instagram: string
  biography: string
}

type ReferralProps = { referral: string }

type HandleLoginAndCreateReferralResult = boolean | 'referrerExists'

export type UpdateUserEmailProps = {
  email: string
}

const LOGIN_BY_ADDRESS = gql`
  mutation LoginByAddress($address: String!, $message: String!, $signature: String!) {
    loginByAddress(request: { address: $address, message: $message, signature: $signature }) {
      token
      user {
        address
        username
        email
        isEmailVerified
        twitter
        instagram
        biography
        referralCode
        referral {
          referrerCode
        }
        mailTopicSubscription {
          address
          topic
          email
        }
      }
    }
  }
`

const initialState: State = {
  isLoggedIn: false,
  token: null,
  connectTo: 'telegram',
  user: {
    address: '',
    username: '',
    email: '',
    isEmailVerified: false,
    twitter: '',
    instagram: '',
    biography: '',
    telegram: null,
    discord: null,
    referralCode: '',
    referral: {
      referrerCode: ''
    },
    mailTopicSubscription: []
  }
}

const UserContext = createContext<UserContextProps>(initialState)

export const useUserContext = () => useContext(UserContext)

export enum UserReducer {
  setLogin = 'setLogin',
  updateUser = 'updateUser',
  connectTo = 'connectTo',
  updateReferral = 'updateReferral',
  refreshToken = 'refreshToken'
}

const reducer = (state: State, { action, payload }: ReducerActions<UserReducer>) => {
  switch (action) {
    case UserReducer.setLogin:
      return { ...state, isLoggedIn: payload.isLoggedIn, token: payload.token, user: payload.user }
    case UserReducer.updateUser:
      return { ...state, user: payload }
    case UserReducer.connectTo:
      return { ...state, connectTo: payload }
    case UserReducer.updateReferral:
      return { ...state, user: { ...state.user, referral: payload } }
    case UserReducer.refreshToken:
      return { ...state, token: payload.token }
    default:
      return state
  }
}

const UserContextProvider: React.FC = ({ children }) => {
  const { account } = useWeb3React()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { signMessage } = useSignature()
  const { token } = state
  const handleVerifyModal = useVerifyModalAddresModal()
  // const router = useRouter()

  const login = useCallback(async (variables: LoginProps) => {
    try {
      const { data }: FetchResult = await clientNftApi.mutate({
        mutation: LOGIN_BY_ADDRESS,
        variables
      })

      return {
        token: data?.loginByAddress.token,
        user: data?.loginByAddress.user
      }
    } catch (e: any) {
      const { errors } = e
      return {
        error: errors?.message
      }
    }
  }, [])

  const handleLogin = useCallback(async () => {
    try {
      const sign = await signMessage()

      if (sign?.error) {
        return {
          status: false,
          message: sign.error
        }
      } else {
        const result = await login({
          message: sign.message,
          signature: checkOldSignature(sign.signature),
          address: sign.address
        })

        if (result?.error)
          return {
            error: result.error
          }

        // window.localStorage.setItem(LS_TOKEN, result.token)

        const jwt: JwtPayload = decode(result.token)

        if (jwt?.exp) {
          setCookies(account as string, result.token, {
            expires: new Date(jwt.exp * 1000)
          })

          console.log({
            action: UserReducer.setLogin,
            payload: {
              isLoggedIn: !!result.token,
              token: result.token,
              user: result.user
            }
          })

          dispatch({
            action: UserReducer.setLogin,
            payload: {
              isLoggedIn: !!result.token,
              token: result.token,
              user: result.user
            }
          })

          return true
        }

        return false
      }
    } catch (error) {
      console.log('❌ handleLogin error', error)

      return false
    }
  }, [account, login, signMessage])

  const validateUsername = useCallback(
    async (username: string) => {
      try {
        const result = await clientNftApi.mutate({
          mutation: gql`
            mutation ExistUserByUsername($username: String!) {
              existUserByUsername(request: { username: $username }) {
                existed
              }
            }
          `,
          variables: {
            username
          },
          context: {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        })

        return !result.data.existUserByUsername.existed
      } catch (error) {
        return {
          error
        }
      }
    },
    [token]
  )

  const updateUser = useCallback(
    async (props: UpdateUserProps) => {
      try {
        const result = await clientNftApi.mutate({
          mutation: gql`
            mutation UpdateUser($username: String!, $email: String!, $twitter: String!, $instagram: String!, $biography: String!) {
              updateUser(request: { username: $username, email: $email, twitter: $twitter, instagram: $instagram, biography: $biography }) {
                user {
                  address
                  username
                  email
                  isEmailVerified
                  twitter
                  instagram
                  biography
                  referralCode
                  referral {
                    referrerCode
                  }
                }
                emailSent
              }
            }
          `,
          variables: {
            ...props
          },
          context: {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        })

        return result.data.updateUser
      } catch (error) {
        return {
          error
        }
      }
    },
    [token]
  )

  const updateUserEmail = useCallback(
    async (props: UpdateUserEmailProps) => {
      const result = await clientNftApi.mutate({
        mutation: gql`
          mutation UpdateUser($email: String!) {
            updateUser(request: { email: $email }) {
              user {
                email
              }
            }
          }
        `,
        variables: {
          ...props
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return result.data.updateUser
    },
    [token]
  )

  const currentUser = useCallback(
    async (userToken: string | undefined = undefined) => {
      try {
        const result = await clientNftApi.query({
          query: gql`
            query CurrentUser {
              currentUser {
                address
                username
                email
                isEmailVerified
                twitter
                instagram
                biography
                referralCode
                referral {
                  referrerCode
                }
                discord {
                  username
                  userAddress
                }
                telegram {
                  username
                  userAddress
                }
                mailTopicSubscription {
                  address
                  topic
                  email
                }
              }
            }
          `,
          context: {
            headers: {
              authorization: `Bearer ${userToken ?? token}`
            }
          }
        })

        return result.data.currentUser
      } catch (error) {
        // window.localStorage.removeItem(LS_TOKEN)
        console.log({ error })
        removeCookies(account as string)

        handleVerifyModal({ expired: false })
        // router.reload()
        return {
          error
        }
      }
    },
    [account, handleVerifyModal, token]
  )

  const refreshToken = useCallback(async (userToken: string): Promise<string> => {
    const result = await clientNftApi.mutate({
      mutation: gql`
        mutation RefreshToken($token: String) {
          refreshToken(request: { token: $token }) {
            token
          }
        }
      `,
      variables: {
        token: userToken
      },
      context: {
        headers: {
          authorization: `Bearer ${userToken}`
        }
      }
    })

    dispatch({
      action: UserReducer.refreshToken,
      payload: {
        token: result.data.refreshToken.token
      }
    })

    return result.data.refreshToken.token
  }, [])

  const telegramConnect = useMutation(async () => {
    const {
      data: {
        telegramConnect: { url }
      }
    } = await clientNftApi.mutate({
      mutation: gql`
        mutation TelegramConnect($address: String) {
          telegramConnect(request: { address: $address }) {
            url
          }
        }
      `,
      variables: {
        address: account
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    })

    return url
  })

  const telegramDisconnect = useMutation(async () => {
    const {
      data: {
        telegramDisconnect: { ok }
      }
    } = await clientNftApi.mutate({
      mutation: gql`
        mutation TelegramDisconnect($address: String) {
          telegramDisconnect(request: { address: $address }) {
            ok
          }
        }
      `,
      variables: {
        address: account
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    })

    if (ok) {
      currentUser(token)
        .then(result => {
          dispatch({
            action: UserReducer.updateUser,
            payload: result
          })
        })
        .catch(error => {
          console.log('currentUser error', error)
        })
    }

    return ok
  })

  const discordConnect = useMutation(async () => {
    const {
      data: {
        discordConnect: { url }
      }
    } = await clientNftApi.mutate({
      mutation: gql`
        mutation DiscordConnect($address: String) {
          discordConnect(request: { address: $address }) {
            url
          }
        }
      `,
      variables: {
        address: account
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    })

    return url
  })

  const discordDisconnect = useMutation(async () => {
    const {
      data: {
        discordDisconnect: { ok }
      }
    } = await clientNftApi.mutate({
      mutation: gql`
        mutation DiscordDisconnect($address: String) {
          discordDisconnect(request: { address: $address }) {
            ok
          }
        }
      `,
      variables: {
        address: account
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    })

    if (ok) {
      currentUser(token)
        .then(result => {
          dispatch({
            action: UserReducer.updateUser,
            payload: result
          })
        })
        .catch(error => {
          console.log('currentUser error', error)
        })
    }

    return ok
  })

  const sendEmailVerification = useCallback(async () => {
    try {
      const result = await clientNftApi.mutate({
        mutation: gql`
          mutation SendEmailVerification($address: String!) {
            sendVerifyEmail(request: { address: $address }) {
              ok
            }
          }
        `,
        variables: {
          address: account
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return result.data.sendEmailVerify.ok
    } catch (error) {
      return {
        error
      }
    }
  }, [account, token])

  const createReferral = useMutation(
    async ({ referral }: ReferralProps) => {
      const {
        data: {
          createReferral: { referral: referralResult }
        }
      } = await clientNftApi.mutate({
        mutation: gql`
          mutation CreateReferral($address: String!) {
            createReferral(request: { referrerCode: $address }) {
              referral {
                referrerCode
              }
            }
          }
        `,
        variables: {
          address: referral
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      })

      return referralResult
    },
    {
      onSuccess: payload => {
        dispatch({
          action: UserReducer.updateReferral,
          payload
        })
      }
    }
  )

  const handleLoginAndCreateReferral = useCallback(
    async ({ referral }: ReferralProps): Promise<HandleLoginAndCreateReferralResult> => {
      const login = await handleLogin()

      if (login) {
        const result = await createReferral.mutateAsync({ referral })
        if (result) {
          currentUser(token)
            .then(result => {
              dispatch({
                action: UserReducer.updateUser,
                payload: result
              })
            })
            .catch(error => {
              console.log('currentUser error', error)
            })
          return true
        }
        return false
      }

      return false
    },
    [createReferral, currentUser, handleLogin, token]
  )

  const isUserSubscribedMailTopic = useCallback(
    (topic: 'auction'): boolean =>
      !!state.user?.mailTopicSubscription?.find((newsletter: Newsletter) => newsletter.topic.toLowerCase() === topic.toLowerCase()),
    [state.user?.mailTopicSubscription]
  )

  useEffect(() => {
    // const token = window.localStorage.getItem(LS_TOKEN)
    const token = getCookie(account as string)
    //if (!token) return
    currentUser(token as string)
      .then(result => {
        dispatch({
          action: UserReducer.setLogin,
          payload: {
            isLoggedIn: result.address ? true : false,
            token: token,
            user: result
          }
        })
      })
      .catch(error => {
        console.log('currentUser error', error)
      })
  }, [account, currentUser])

  return (
    <UserContext.Provider
      value={{
        ...state,
        dispatch,
        handleLogin,
        validateUsername,
        updateUser,
        updateUserEmail,
        currentUser,
        refreshToken,
        telegramConnect,
        telegramDisconnect,
        discordConnect,
        discordDisconnect,
        sendEmailVerification,
        createReferral,
        handleLoginAndCreateReferral,
        isUserSubscribedMailTopic
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
