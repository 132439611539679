import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache, split } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getEndpoint } from 'utils'

const httpLinkBendProtocol = new HttpLink({ uri: getEndpoint('BEND_PROTOCOL') })
// const wsLinkBendProtocol = new WebSocketLink({
//   uri: getEndpoint('BEND_PROTOCOL_WS'),
//   options: {
//     reconnect: true,
//   }
// })
const wsLinkBendProtocol = process.browser
  ? new WebSocketLink(
      new SubscriptionClient(getEndpoint('BEND_PROTOCOL_WS'), {
        lazy: true,
        reconnect: true
      })
    )
  : httpLinkBendProtocol

// const httpLinkBend = new HttpLink({ uri: getEndpoint('BEND') })

const httpLinkBendNftApi = new HttpLink({ uri: getEndpoint('BEND_NFTAPI') })

const httpLinkBendBNft = new HttpLink({ uri: getEndpoint('BEND_BNFT') })

const httpLinkNftInfo = new HttpLink({ uri: getEndpoint('NFT_INFO') })
const httpLinkCms = new HttpLink({ uri: getEndpoint('CMS') })
const httpLinkApeStaking = new HttpLink({ uri: getEndpoint('APESTAKING') })
const httpLinkUniswapV2 = new HttpLink({ uri: getEndpoint('UNISWAP_V2_API') })

const errorLink = onError(({ graphQLErrors }: ErrorResponse) => {
  if (!!graphQLErrors?.length) {
    graphQLErrors.map(graphQLError => {
      console.log('❌ GraphQL error:', graphQLError.message)
    })
  }
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

const splitLink =
  typeof window !== 'undefined' && getEndpoint('BEND_PROTOCOL_WS') !== ''
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        },
        wsLinkBendProtocol,
        httpLinkBendProtocol
      )
    : httpLinkBendProtocol

export const clientBendProtocol = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: errorLink.concat(splitLink),
  cache: new InMemoryCache(),
  defaultOptions
})

// export const clientBend = new ApolloClient({
//   link: errorLink.concat(httpLinkBend),
//   cache: new InMemoryCache(),
//   defaultOptions
// })

export const clientNftApi = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: errorLink.concat(httpLinkBendNftApi),
  cache: new InMemoryCache(),
  defaultOptions
})

export const clientBendBnft = new ApolloClient({
  link: errorLink.concat(httpLinkBendBNft),
  cache: new InMemoryCache(),
  defaultOptions
})

export const clientNftInfo = new ApolloClient({
  link: errorLink.concat(httpLinkNftInfo),
  cache: new InMemoryCache(),
  defaultOptions
})

export const clientCms = new ApolloClient({
  link: errorLink.concat(httpLinkCms),
  cache: new InMemoryCache(),
  defaultOptions
})

export const clientApeStaking = new ApolloClient({
  link: errorLink.concat(httpLinkApeStaking),
  cache: new InMemoryCache(),
  defaultOptions
})

export const clientUniswapV2 = new ApolloClient({
  link: errorLink.concat(httpLinkUniswapV2),
  cache: new InMemoryCache(),
  defaultOptions
})
