import { useMemo } from 'react'

import { /* Connection,*/ ConnectionType } from '../connection'
import { getConnection, useWallet } from '../index'

const SELECTABLE_WALLETS = [
  ConnectionType.INJECTED,
  ConnectionType.COINBASE_WALLET,
  ConnectionType.OKX_WALLET,
  ConnectionType.TRUST_WALLET,
  ConnectionType.GNOSIS_SAFE,
  ConnectionType.WALLET_CONNECT_V2
]

export default function useOrderedConnections() {
  const { selectedWalletType } = useWallet()
  return useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = []

    // Always attempt to use to Gnosis Safe first, as we can't know if we're in a SafeContext.
    orderedConnectionTypes.push(ConnectionType.GNOSIS_SAFE)
    orderedConnectionTypes.push(ConnectionType.WALLET_CONNECT_V2)

    // Add the `selectedWallet` to the top so it's prioritized, then add the other selectable wallets.
    if (selectedWalletType) {
      orderedConnectionTypes.push(selectedWalletType)
    }
    orderedConnectionTypes.push(...SELECTABLE_WALLETS.filter(wallet => wallet !== selectedWalletType))

    // Add network connection last as it should be the fallback.
    orderedConnectionTypes.push(ConnectionType.NETWORK)

    return orderedConnectionTypes.map(getConnection) ?? []
  }, [selectedWalletType])
}
