import { rgba } from 'polished'

const darkTheme = {
  grey: {
    1000: '#000000',
    900: '#181818',
    800: '#323232',
    700: '#4b4b4b',
    600: '#656565',
    500: '#7e7e7e',
    400: '#989898',
    300: '#b1b1b1',
    310: '#9A9FA9',
    200: '#cbcbcb',
    100: '#e4e4e4',
    50: '#f6f7f9',
    20: '#e4e4e440',
    5: '#EEEFF0',
    4: '#F2F2F2'
  },
  blue: {
    //100:    '#4547A1',
    //200:    '#353671',
    1000: '#0057ff',
    900: '#1967ff',
    800: '#3378ff',
    700: '#4c89ff',
    600: '#669aff',
    500: '#7faaff',
    400: '#99bcff',
    300: '#b2ccff',
    200: '#ccddff',
    100: '#e5eeff',
    50: '#3E5267',
    10: '#2A3343',
    1: '#74A3FF'
  },
  red: {
    //'#EF5350',
    1100: '#FF9900',
    1000: '#ef5350',
    900: '#f06361',
    800: '#f27572',
    700: '#f38683',
    600: '#f59795',
    500: '#f6a8a6',
    400: '#f8b9b8',
    300: '#facac9',
    200: '#fbdcdb',
    100: '#fdecec'
  },
  green: {
    //100:    '#26A69A',
    //200:    '#30E98D'
    1100: '#84DB00',
    1000: '#00bc64',
    900: '#18c273',
    800: '#32c982',
    700: '#4bcf92',
    600: '#65d6a1',
    500: '#7eddb0',
    400: '#98e4c0',
    300: '#b1eacf',
    200: '#cbf1df',
    100: '#e4f7ee'
  },
  yellow: {
    //100:    '#FFC400',
    //200:    '#F0B90B',
    1000: '#ffc400',
    900: '#ffc918',
    800: '#ffcf32',
    700: '#ffd54b',
    600: '#ffdb65',
    500: '#ffe17e',
    400: '#ffe798',
    300: '#ffedb1',
    200: '#fff3cb',
    100: '#fff8e4'
  },
  orange: {
    100: '#F5AC37',
    200: '#FF8800',
    300: '#FFF0DF',
    400: '#FFC27D'
  },
  shadow: {
    100: rgba(0, 0, 0, 0.08),
    200: rgba(0, 0, 0, 0.16),
    300: rgba(0, 0, 0, 0.32)
  },

  black: '#000000',
  white: '#ffffff',

  body: '#f6f7f9',
  backdrop: rgba(0, 0, 0, 0.6),
  'backdrop-light': rgba(255, 255, 255, 0.75),

  primary: '#fd820e',
  secondary: '#000000',

  'text-primary': '#000000',
  palceholder: '#b1b1b1',

  info: '#0057ff',
  success: '#00bc64',
  error: '#ef5350',
  warning: '#ffc400',

  bg: {
    info: '#e5eeff',
    success: '#e4f7ee',
    error: '#fdecec',
    warning: '#fff8e4'
  },

  cyan: '#00E5FF',
  magenta: '#DA40FF',
  cryptopunk: '#638596',
  headerLabel: '#7484A4',
  /*purple: {
    100:      '#D0D1E7',
    200:      '#8A8EFF',
    300:      '#4547A1',
    400:      '#2D2E58',
    chartTop:     'rgba(69, 71, 161, 0.15)',
    chartBottom:  'rgba(69, 71, 161, 0.00)'
  }*/
  dashboardLabel: '#7F7F7F'
}

export default darkTheme
