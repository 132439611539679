import { ReactNode, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ApolloProvider } from '@apollo/client'
import ProgressBar from '@badrap/bar-of-progress'
import { clientNftApi } from 'clients'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import NotificationsProvider from 'contexts/notifications'
import { PlatformContextProvider } from 'contexts/platform'
import UserContextProvider from 'contexts/user'
// import TermsAndServiceNotification from 'components/common/terms-and-service-notification'
import { getCookie, setCookies } from 'cookies-next'
import { MulticallContextProvider } from 'multicall-module'
import dynamic from 'next/dynamic'
// import { I18nextProvider } from 'react-i18next'
import Router, { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'styled-components'
import { Reset } from 'styled-reset'
import { DrawerProvider, ModalsProvider } from 'theme/ui'
import { WalletContextProvider } from 'wallet-module'
import BtcWalletProvider from 'wallet-module/contexts/btcWallet'

import AppNotificationsRight from 'components/common/app-notofications/app-notifications-right'
import AppNotificationsProvider from 'components/common/app-notofications/context'
import BreadcrumbsProvider from 'components/common/breadcrumbs/context'
import ErrorBoundary from 'components/common/error-boundary'
import Head from 'components/common/head'
import Header from 'components/common/header'
import LandingHeader from 'components/common/header/landing-header'
import { ModalAppProvider } from 'components/common/modal/components/context'
import TestnetUsageIcon from 'components/common/testnet-notification/icon'
import TransactionModalCustomStepProvider from 'components/common/transaction-modal-custom-step/context'
import TransactionModalProvider from 'components/common/TransactionModal/context'
//import ValidateToken from 'components/common/validate-token'
import WalletCheckProvider from 'components/common/wallet-check/context'
import Web3Provider from 'components/common/web3-default-provider'

import { BreadcrumbsContextProvider, DataContextProvider, MobileContextProvider, ResponsiveContextProvider, WizardContextProvider } from '../contexts'
import useDefaultTheme, { GlobalStyle } from '../theme'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

const ValidateToken: any = dynamic(() => import('components/common/validate-token').then((module: any) => module.default), { ssr: false })

const _bend_version = '_bend_version'
const BEND_VERSION = getCookie(_bend_version)

const progress = new ProgressBar({
  size: 2,
  color: '#0057ff',
  className: 'progressbar',
  delay: 100
})

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

//if (typeof window === 'undefined') return context
if (typeof window !== 'undefined' && 'ethereum' in window) {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

/**
 * React query client
 */
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false
    }
  }
})

const App = ({ Component, pageProps }: any) => {
  const { route } = useRouter()

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_VERSION !== BEND_VERSION) {
      setCookies('_bend_version', process.env.NEXT_PUBLIC_APP_VERSION, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      })
    }
  }, [])

  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return (
    <Head title={pageProps?.seo?.title} description={pageProps?.seo?.description} image={pageProps?.seo?.image}>
      <ErrorBoundary>
        <ApolloProvider client={clientNftApi}>
          <QueryClientProvider client={client}>
            <BtcWalletProvider>
              <Web3Provider>
                <ModalAppProvider>
                  <Theme>
                    <AppNotificationsProvider>
                      <WalletContextProvider>
                        <MulticallContextProvider>
                          <ResponsiveContextProvider>
                            <DrawerProvider>
                              <WizardContextProvider>
                                <BreadcrumbsContextProvider>
                                  <DataContextProvider>
                                    <UserContextProvider>
                                      <ModalsProvider>
                                        <MobileContextProvider>
                                          <DrawerProvider>
                                            <NotificationsProvider>
                                              {/* <TermsAndServiceNotification /> */}
                                              {DEFAULT_CHAIN_ID === '686868' && <TestnetUsageIcon />}
                                              <PlatformContextProvider>
                                                <WalletCheckProvider>
                                                  <BreadcrumbsProvider>
                                                    <TransactionModalProvider>
                                                      <TransactionModalCustomStepProvider>
                                                        <AppNotificationsRight />
                                                        {route === '/' ? null : route === '/[locale]' ? <LandingHeader /> : <Header />}
                                                        <ValidateToken />
                                                        {getLayout(<Component {...pageProps} />)}
                                                      </TransactionModalCustomStepProvider>
                                                    </TransactionModalProvider>
                                                  </BreadcrumbsProvider>
                                                </WalletCheckProvider>
                                              </PlatformContextProvider>
                                            </NotificationsProvider>
                                          </DrawerProvider>
                                        </MobileContextProvider>
                                      </ModalsProvider>
                                    </UserContextProvider>
                                  </DataContextProvider>
                                </BreadcrumbsContextProvider>
                              </WizardContextProvider>
                            </DrawerProvider>
                          </ResponsiveContextProvider>
                        </MulticallContextProvider>
                      </WalletContextProvider>
                    </AppNotificationsProvider>
                  </Theme>
                </ModalAppProvider>
              </Web3Provider>
            </BtcWalletProvider>
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </Head>
  )
}

export default appWithTranslation(App)

const Theme: React.FC = ({ children }) => {
  const theme = useDefaultTheme()

  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle {...theme} />
      {children}
    </ThemeProvider>
  )
}
