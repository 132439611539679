import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { removeCookies } from 'cookies-next'
import useTheme from 'hooks/common/useTheme'
import { getIsOkxWallet } from 'modules/wallet-module/utils'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass'
import { Avatar, Button, IconCopy, IconDisconnect, IconWallet } from 'theme/ui'
import { getConnection, getConnectionName, getIsCoinbaseWallet, getIsMetaMask, useWallet } from 'wallet-module'

import { Ens } from 'components/common/ens'

import CopyHelper from './copy'

export const EthWalletStep1: React.FC<any> = ({ account, handleShowChangeProvider }) => {
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { connector } = useWeb3React()
  const { colors } = useTheme()
  const { WalletDispatch } = useWallet()
  //console.log('connector', connector, 'isMetaMask:', getIsMetaMask(), 'isCoinbaseWallet:', getIsCoinbaseWallet(), 'okx', getIsOkxWallet())
  const connectorName = useMemo(() => {
    if (!connector) return <></>
    const name = getConnectionName({
      connectionType: getConnection(connector)!.type,
      isMetaMask: getIsMetaMask(),
      isCoinbaseWallet: getIsCoinbaseWallet(),
      isOkxWallet: getIsOkxWallet()
    })
    return <Text>{t('wallet.label.connected-with', { name })}</Text>
  }, [connector, t])

  return (
    <>
      {account && (
        <Text fontWeight='bold' fontSize={13}>
          {tc('label.connected-btc-wallet')}
        </Text>
      )}
      <Flex alignItems='center' flex={1} mt={20} mb={20}>
        {account && (
          <Avatar
            width='100%'
            hasBorderRadius={8}
            icon={<IconWallet size={14} color='white' />}
            iconBackground={account ? 'green.1000' : 'red.1000'}
            iconSize={32}
            hasBackground
            text={
              <Flex flex={1} justifyContent='space-between' width='100%'>
                <Text fontWeight='bold' mr={38}>
                  {account && <Ens address={account} maxLength={20} />}
                </Text>

                <Flex sx={{ position: 'fixed right' }}>
                  <CopyHelper toCopy={account!} color='transparent' fontColor='black' fontWeight='bold' fontSize='md'>
                    <IconCopy color={colors.grey[500]} />
                  </CopyHelper>

                  {
                    //connector !== injected && (
                    <Button
                      size='icon'
                      color='transparent'
                      ml={5}
                      onClick={async () => {
                        try {
                          if (connector.deactivate) {
                            connector.deactivate()
                            WalletDispatch({
                              type: 'UPDATE',
                              payload: {
                                selectedWalletType: undefined
                              }
                            })
                            removeCookies('selectedWalletType')
                          } else {
                            connector.resetState()
                            WalletDispatch({
                              type: 'UPDATE',
                              payload: {
                                selectedWalletType: undefined
                              }
                            })
                            removeCookies('selectedWalletType')
                          }
                        } catch (error) {
                          console.log('deactivate error', error)
                        }
                      }}
                    >
                      <IconDisconnect color={colors.grey[500]} />
                    </Button>
                    //)
                  }
                </Flex>
              </Flex>
            }
          />
        )}
      </Flex>
      {account && (
        <Flex fontSize={12} justifyContent='space-between'>
          <Text sx={{ opacity: 0.5 }}>{connectorName}</Text>
          <Text color={colors.primary} sx={{ ':hover': { cursor: 'pointer', opacity: 0.8, transition: 'ease-in-out 0.2' } }} onClick={handleShowChangeProvider}>
            {tc('label.change-provider')}
          </Text>
        </Flex>
      )}
    </>
  )
}
