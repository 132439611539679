import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { ChainId } from '../constants'

import { Connection, ConnectionType } from '.'

// const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '1'
const WALLET_CONNECT_V2_ID = process.env.NEXT_PUBLIC_WALLET_CONNECT_V2_ID || '04569ab152ac975ef1c23a2d31915f0d'
export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  actions =>
    new WalletConnectV2({
      actions,
      defaultChainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID || 1),
      options: {
        projectId: WALLET_CONNECT_V2_ID,
        chains: [Number(process.env.NEXT_PUBLIC_CHAIN_ID || 1)],
        optionalChains: [ChainId.MERLIN, ChainId.MERLIN_TESTNET],
        showQrModal: true,
        rpcMap: [process.env.NEXT_PUBLIC_NETWORK_URL as string],
        qrModalOptions: {
          themeVariables: {
            '--w3m-z-index': '8000'
          }
        },
        optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign']
      }
    })
)

export const walletConnectConnectionV2: Connection = {
  connector: walletConnectV2,
  hooks: hooks,
  type: ConnectionType.WALLET_CONNECT_V2
}
