import React, { useEffect, useMemo, useRef } from 'react'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useDebounce } from 'use-debounce'
// Hooks
import { CancelledError, retry, RetryableError, useBlockNumber } from 'wallet-module'

import { useMulticallContract } from '../hooks/useContract'
// Constants
import { Call } from '../interfaces'
import { chunkArray, parseCallKey, toCallKey } from '../utils'

// Interfaces
import { MulticallContextProps, MulticallStateProps, ReducerActionProps } from './interfaces'
// Components
// import { ReloadNotification } from '../components'

const initialState: MulticallStateProps = {
  callResults: {}
}

const reducer = (state: MulticallStateProps, { type, payload }: ReducerActionProps) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...payload }
    default:
      return state
  }
}

export const MulticallContext = React.createContext<MulticallContextProps>({
  ...initialState
})

// chunk calls so we do not exceed the gas limit
const CALL_CHUNK_SIZE = 500

/**
 * Fetches a chunk of calls, enforcing a minimum block number constraint
 * @param multicallContract multicall contract to fetch against
 * @param chunk chunk of calls to make
 * @param minBlockNumber minimum block number of the result set
 */
async function fetchChunk(multicallContract: Contract, chunk: Call[], minBlockNumber: number): Promise<{ results: string[]; blockNumber: number }> {
  if (DEFAULT_CHAIN_ID === '686868') console.debug('Fetching chunk', chunk, minBlockNumber)
  if (!multicallContract) return { results: [], blockNumber: minBlockNumber }
  let resultsBlockNumber, returnData
  try {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;[resultsBlockNumber, returnData] = await multicallContract.aggregate(chunk.map(obj => [obj.address, obj.callData]), true)
  } catch (error) {
    console.debug('Failed to fetch chunk inside retry', error)
    //throw error
  }

  if (resultsBlockNumber?.toNumber() < minBlockNumber) {
    console.debug(`Fetched results for old block number: ${resultsBlockNumber.toString()} vs. ${minBlockNumber}`)
    throw new RetryableError('Fetched for old block number')
  }

  const successResultData: string[] = []
  if (returnData) {
    returnData.forEach((d: { success: Boolean, data: string }) => {
      if (d.success) successResultData.push(d.data)
    })
  }

  return { results: successResultData, blockNumber: resultsBlockNumber?.toNumber() || 0 }
}

/**
 * From the current all listeners state, return each call key mapped to the
 * minimum number of blocks per fetch. This is how often each key must be fetched.
 * @param allListeners the all listeners state
 * @param chainId the current chain id
 */
export function activeListeningKeys(
  //allListeners: AppState['multicall']['callListeners'],
  allListeners: any,
  chainId?: number
): { [callKey: string]: number } {
  if (!allListeners || !chainId) return {}
  const listeners = allListeners[chainId]

  if (!listeners) return {}

  const returnKeys = Object.keys(listeners).reduce<{
    [callKey: string]: number
  }>((memo, callKey) => {
    const keyListeners = listeners[callKey]

    memo[callKey] = Object.keys(keyListeners)
      .filter(key => {
        const blocksPerFetch = parseInt(key)
        if (blocksPerFetch <= 0) return false
        return keyListeners[blocksPerFetch] > 0
      })
      .reduce((previousMin, current) => {
        return Math.min(previousMin, parseInt(current))
      }, Infinity)
    return memo
  }, {})
  return returnKeys
}

/**
 * Return the keys that need to be refetched
 * @param callResults current call result state
 * @param listeningKeys each call key mapped to how old the data can be in blocks
 * @param chainId the current chain id
 * @param latestBlockNumber the latest block number
 */
export function outdatedListeningKeys(
  //callResults: AppState['multicall']['callResults'],
  callResults: any,
  listeningKeys: { [callKey: string]: number },
  chainId: number | undefined,
  latestBlockNumber: number | undefined
): string[] {
  if (!chainId || !latestBlockNumber) return []
  const results = callResults[chainId]
  // no results at all, load everything
  if (!results) return Object.keys(listeningKeys)

  return Object.keys(listeningKeys).filter(callKey => {
    const blocksPerFetch = listeningKeys[callKey]

    const data = callResults[chainId][callKey]
    // no data, must fetch
    if (!data) return true

    const minDataBlockNumber = latestBlockNumber - (blocksPerFetch - 1)

    // already fetching it for a recent enough block, don't refetch it
    if (data.fetchingBlockNumber && data.fetchingBlockNumber >= minDataBlockNumber) return false

    // if data is older than minDataBlockNumber, fetch it
    return !data.blockNumber || data.blockNumber < minDataBlockNumber
  })
}

const MulticallContextProvider: React.FC = ({ children }) => {
  const [MulticallState, MulticallDispatch] = React.useReducer(reducer, initialState)
  const { callListeners, callResults } = MulticallState

  // wait for listeners to settle before triggering updates
  const debouncedListeners = useDebounce(callListeners, 300)
  const latestBlockNumber = useBlockNumber()
  const { chainId, connector } = useWeb3React()
  const multicallContract = useMulticallContract()
  const cancellations = useRef<{ blockNumber: number; cancellations: (() => void)[] }>()

  const listeningKeys: { [callKey: string]: number } = useMemo(() => {
    const activeKeys = activeListeningKeys(debouncedListeners[0], chainId)
    return activeKeys
  }, [chainId, debouncedListeners])

  const unserializedOutdatedCallKeys = useMemo(() => {
    return outdatedListeningKeys(callResults, listeningKeys, chainId, latestBlockNumber)
  }, [chainId, callResults, listeningKeys, latestBlockNumber])

  const serializedOutdatedCallKeys = useMemo(() => JSON.stringify(unserializedOutdatedCallKeys.sort()), [unserializedOutdatedCallKeys])

  useEffect(() => {
    if (!latestBlockNumber || !chainId || !multicallContract) return

    const outdatedCallKeys: string[] = JSON.parse(serializedOutdatedCallKeys)
    if (outdatedCallKeys.length === 0) return
    const calls = outdatedCallKeys.map(key => parseCallKey(key))

    const chunkedCalls = chunkArray(calls, CALL_CHUNK_SIZE)

    if (cancellations.current?.blockNumber !== latestBlockNumber) {
      cancellations.current?.cancellations?.forEach(c => c())
    }

    const newCallResults = callResults ?? {}
    newCallResults[chainId] = callResults[chainId] ?? {}
    calls.forEach(call => {
      const callKey = toCallKey(call)
      const current = newCallResults[chainId][callKey]
      if (!current) {
        newCallResults[chainId][callKey] = {
          fetchingBlockNumber: latestBlockNumber
        }
      } else {
        if ((current.fetchingBlockNumber ?? 0) >= latestBlockNumber) return
        newCallResults[chainId][callKey].fetchingBlockNumber = latestBlockNumber
        if (current?.callback) current.callback(current)
      }
    })

    MulticallDispatch({
      type: 'UPDATE',
      payload: {
        callResults: newCallResults
      }
    })

    cancellations.current = {
      blockNumber: latestBlockNumber,
      cancellations: chunkedCalls.map((chunk, index) => {
        const { cancel, promise } = retry(() => fetchChunk(multicallContract, chunk, latestBlockNumber), {
          n: Infinity,
          minWait: 2500,
          maxWait: 3500
        })
        promise
          .then(({ results: returnData, blockNumber: fetchBlockNumber }) => {
            cancellations.current = {
              cancellations: [],
              blockNumber: latestBlockNumber
            }

            // accumulates the length of all previous indices
            const firstCallKeyIndex = chunkedCalls.slice(0, index).reduce<number>((memo, curr) => memo + curr.length, 0)
            const lastCallKeyIndex = firstCallKeyIndex + returnData.length

            const results = outdatedCallKeys.slice(firstCallKeyIndex, lastCallKeyIndex).reduce<{ [callKey: string]: string | null }>((memo, callKey, i) => {
              memo[callKey] = returnData[i] ?? null
              return memo
            }, {})

            const newCallResults = callResults ?? {}
            newCallResults[chainId] = callResults[chainId] ?? {}
            Object.keys(results).forEach(callKey => {
              const current = newCallResults[chainId][callKey]
              if ((current?.blockNumber ?? 0) > fetchBlockNumber) return
              newCallResults[chainId][callKey] = {
                data: results[callKey],
                blockNumber: fetchBlockNumber
              }
            })

            MulticallDispatch({
              type: 'UPDATE',
              payload: {
                callResults: newCallResults
              }
            })
          })
          .catch((error: any) => {
            if (error instanceof CancelledError) {
              if (DEFAULT_CHAIN_ID === '686868') console.debug('Cancelled fetch for blockNumber', latestBlockNumber)
              return
            }
            console.error('Failed to fetch multicall chunk', chunk, chainId, error)
            const newCallResults = callResults ?? {}
            newCallResults[chainId] = callResults[chainId] ?? {}
            calls.forEach(call => {
              const callKey = toCallKey(call)
              const current = newCallResults[chainId][callKey]
              if (!current) return // only should be dispatched if we are already fetching
              if (current.fetchingBlockNumber === latestBlockNumber) {
                delete current.fetchingBlockNumber
                current.data = null
                current.blockNumber = latestBlockNumber
              }
            })
            MulticallDispatch({
              type: 'UPDATE',
              payload: {
                callResults: newCallResults
              }
            })
          })
        return cancel
      })
    }
  }, [chainId, multicallContract, MulticallDispatch, callResults, serializedOutdatedCallKeys, latestBlockNumber, connector])

  return (
    <MulticallContext.Provider
      value={{
        ...MulticallState,
        MulticallDispatch
      }}
    >
      {children}
    </MulticallContext.Provider>
  )
}

export default MulticallContextProvider
