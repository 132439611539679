import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { useDebounce } from 'use-debounce'
import { ChainId, useWallet } from 'wallet-module'

/**
 * Does a reverse lookup for an address to find its ENS name.
 * Note this is not the same as looking up an ENS name to find an address.
 */
export default function useENSName(address?: string): { ENSName: string | null | undefined; loading: boolean } {
  const { provider } = useWeb3React()
  const { wsProvider } = useWallet()
  const debouncedAddress = useDebounce(address, 200)

  const { data, isLoading } = useQuery(['get ens name', debouncedAddress[0]], async () => {
    if (!debouncedAddress || !debouncedAddress[0] || Number(DEFAULT_CHAIN_ID) === ChainId.MERLIN || Number(DEFAULT_CHAIN_ID) === ChainId.MERLIN_TESTNET)
      return null
    if (!provider) {
      return wsProvider.lookupAddress(debouncedAddress[0])
    }
    return await provider.lookupAddress(debouncedAddress[0])
  })

  return useMemo(
    () => ({
      ENSName: data ? data : null,
      loading: isLoading
    }),
    [data, isLoading]
  )
}
