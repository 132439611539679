import { ChainId } from 'wallet-module'

import { ListenerOptions } from '../interfaces'

import MULTICALL_ABI from './abis/multicall.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MERLIN_TESTNET]: '0x70bB12Bd8B553C532a1a9EB943978689eACd7586',
  [ChainId.MERLIN]: '0x4398C643e4FA26420B49AcA0d3EeCD96a7C20e5e'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
  blocksPerFetch: Infinity
}
