import { permalink } from './routes'
import { IRoutes } from './types'

export const routesMerlin: IRoutes[] = [
  {
    type: 'nav-item',
    name: 'Home',
    icon: 'IconHome',
    route: '/',
    auth: false,
    external: false
  },
  {
    type: 'nav-item',
    name: 'Borrow',
    icon: 'IconBorrow',
    route: permalink.batchBorrow,
    auth: false,
    external: false
  },
  {
    type: 'nav-item',
    name: 'Auctions',
    icon: 'IconAuction',
    route: permalink.auction,
    customRoute: permalink.auctionAvailableToAuction,
    auth: false,
    external: false,
    children: [
      {
        type: 'nav-item',
        name: 'Available to Auction',
        icon: 'IconActiveAuctions2',
        route: permalink.auctionAvailableToAuction,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Loans in Auction',
        icon: 'IconAuction',
        route: permalink.auctionLoansInAuction,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Health factor Alert list',
        icon: 'IconAuctionAlert',
        route: permalink.auctionHealthFactorAlertList,
        auth: false,
        external: false
      },
      {
        type: 'nav-item',
        name: 'Auction history',
        icon: 'IconHistory',
        route: permalink.auctionHistory,
        auth: false,
        external: false
      }
    ]
  },
  {
    type: 'nav-item',
    name: 'Community',
    icon: 'IconCollaterals',
    route: '#',
    external: false,
    onClick: (e: any) => e.preventDefault,
    children: [
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Discord',
        icon: 'IconDiscord',
        route: 'https://discord.gg/benddao',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Twitter',
        icon: 'IconTwitter',
        route: 'https://twitter.com/BendDAO_BTC',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'GitHub',
        icon: 'IconGithub',
        route: 'https://github.com/BendDAO',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Dework',
        icon: 'IconDework',
        route: 'https://app.dework.xyz/benddao',
        auth: false,
        external: true
      },
      {
        type: 'nav-item',
        parent: 'Community',
        name: 'Forum',
        icon: 'IconForum',
        route: permalink.forum,
        auth: false,
        external: true
      }
    ]
  }
]
