import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { JsonRpcProvider, WebSocketProvider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { bendXlImagePath, DEFAULT_CHAIN_ID } from 'constants/index'
import { permalink } from 'constants/routes'
import { isEmpty } from 'lodash'
import { WBTC_GATEWAY_ADDRESS } from 'modules/bend/constants'
import { useTranslation } from 'next-i18next'

import useReserves from '../hooks/common/useReserves'

import { BreadcrumbsContext, IBreadcrumbsState } from '.'

type ActiveReserve = {
  id: string
  underlyingAsset: string
}

type MarketIcon = {
  key: string
  title: string
  link: string
  image: string
}

type PlatformContextProviderProps = {
  breadcrumbs: IBreadcrumbsState
  isSupportedNetwork?: boolean
  activeReserve: ActiveReserve
  setActiveReserve: React.Dispatch<React.SetStateAction<ActiveReserve>>
  handleSelectReserve: any
  config?: any
  marketIcons: Array<MarketIcon>
  httpProvider?: JsonRpcProvider
  wsProvider?: WebSocketProvider
  unreadedNotifications: number | string
  setUnreadedNotifications: React.Dispatch<React.SetStateAction<string | number>>
}

export interface Config {
  PUNK_GATEWAY: string
  WETH_GATEWAY: string
  isLoadingConfig: boolean
}

const PlatformContext = createContext<PlatformContextProviderProps>({
  breadcrumbs: {
    breadcrumbTitle: ''
  },
  activeReserve: {
    underlyingAsset: '',
    id: ''
  },
  setActiveReserve: () => { },
  handleSelectReserve: () => { },
  marketIcons: [],
  unreadedNotifications: 0,
  setUnreadedNotifications: () => { }
})

const usePlatform = () => useContext(PlatformContext)

export default usePlatform

export const PlatformContextProvider: React.FC = ({ children }) => {
  const breadcrumbs = useContext(BreadcrumbsContext)
  const { chainId } = useWeb3React()
  const isSupportedNetwork = useMemo((): boolean => `${chainId}` === DEFAULT_CHAIN_ID, [chainId])
  const [unreadedNotifications, setUnreadedNotifications] = useState<string | number>(0)

  const { data: reserves } = useReserves()

  const configResponse = {
    PUNK_GATEWAY: '',
    WBTC_GATEWAY: WBTC_GATEWAY_ADDRESS,
    isLoadingConfig: false
  }

  const [activeReserve, setActiveReserve] = useState({ id: '', underlyingAsset: '' })

  useEffect(() => {
    if (!reserves || isEmpty(reserves) || activeReserve.id !== '' || activeReserve.underlyingAsset !== '') return
    setActiveReserve({
      id: reserves[0].id,
      underlyingAsset: reserves[0].underlyingAsset
    })
  }, [activeReserve.id, activeReserve.underlyingAsset, reserves])

  const handleSelectReserve = useCallback(
    (payload: ActiveReserve) => {
      if (payload.underlyingAsset === activeReserve.underlyingAsset) return
      setActiveReserve(payload)
    },
    [activeReserve.underlyingAsset]
  )

  const { t: tc } = useTranslation('common')
  const marketIcons = useMemo(
    (): Array<MarketIcon> => [
      {
        key: 'benddao',
        title: tc('label.benddao-url'),
        link: permalink.liquidity,
        image: bendXlImagePath
      }
    ],
    [tc]
  )

  return (
    <PlatformContext.Provider
      value={{
        breadcrumbs,
        isSupportedNetwork,
        activeReserve,
        setActiveReserve,
        handleSelectReserve,
        config: configResponse,
        marketIcons,
        unreadedNotifications,
        setUnreadedNotifications
      }}
    >
      {children}
    </PlatformContext.Provider>
  )
}
