import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ChainId } from 'modules/wallet-module'

const useENSAvatar = (account: string | null | undefined = '') => {
  const { provider, chainId } = useWeb3React()

  return useQuery(['get avatar', account, !!provider, chainId], async () => {
    if (!account || !provider || chainId === ChainId.MERLIN || chainId === ChainId.MERLIN_TESTNET) return null
    return await provider.getAvatar(account)
  })
}

export default useENSAvatar
