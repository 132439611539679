import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Flex, Link, LinkProps, SxStyleProp, Text } from 'rebass/styled-components'
import { Button, Container, IconSelector } from 'theme/ui'
import IconError1 from 'theme/ui/icons/bend-icons/icons/icon-error-1'

import { Modal, ModalContainer, useModal } from '../modal'

type ExternalLinkIcon = {
  color?: string
  size?: number
}

type ExternalLinkProps = {
  icon?: ExternalLinkIcon
  hideIcon?: boolean
  xl?: boolean
  styles?: SxStyleProp
}

const ExternalLink: React.FC<LinkProps & ExternalLinkProps> = ({ children, icon, hideIcon, xl = false, styles, ...restprops }) => {
  const { colors } = useTheme()
  const { open, close, modalOpen } = useModal()
  const { isTablet } = useResponsive()
  const { t } = useTranslation()

  const ModalComponent = ({ href, close }: { href: string; close: () => void }) => (
    <ModalContainer modalOpen={modalOpen}>
      <Modal
        handleClose={() => {
          close()
        }}
      >
        <Container variant='card-body-modal' flexDirection='column' maxWidth={['', '', 350]} sx={{ padding: 0 }} justifyContent='flex-start'>
          <Flex
            variant={!isTablet ? 'card5-modal' : 'leaving-benddao-modal'}
            flexDirection='column'
            alignItems='flex-start'
            minWidth={['100%', '100%', 350]}
            p={[20]}
            sx={{ gap: 20, pb: 20 }}
          >
            <Flex sx={{ flexDirection: 'column', gap: 10, width: '100%' }}>
              <Flex sx={{ fontSize: 'sm', fontWeight: 'bold', lineHeight: '13px', gap: 6 }}>
                {t('label.warning')} <IconError1 size={13} color={colors.red[1000]} />
              </Flex>
              <Text
                fontSize='s'
                lineHeight='s'
                dangerouslySetInnerHTML={{
                  __html: t('label.leaving-benddao')
                }}
              />
              <Flex flexWrap='wrap' sx={{ fontSize: 's', lineHeight: 'lg', gap: 3, mt: 5 }}>
                <Text>{t('label.leaving-benddao-1')}</Text>
                <Text sx={{ color: 'label', wordBreak: 'break-all' }}>{href}</Text>
              </Flex>
            </Flex>
            <Flex sx={{ gap: 10, width: '100%' }}>
              <Button size='xl' type='button' flex={1} onClick={() => [close()]} outlined buttonStyles={{ py: 6.5, fontSize: 'xss' }}>
                Cancel
              </Button>
              <Button size='xl' type='button' flex={1} color='primary' onClick={() => [window.open(href), close()]} buttonStyles={{ py: 6.5, fontSize: 'xss' }}>
                Continue
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Modal>
    </ModalContainer>
  )

  if (xl)
    return (
      <>
        <Link target='_blank' rel='noopener noreferrer' onClick={e => [e.preventDefault(), open()]} {...restprops}>
          <Flex as='span' sx={{ alignItems: ['center'], ...styles }}>
            {children}
            <Flex alignItems='center' ml={5} mt={-2}>
              <IconSelector size={icon!.size} color={icon?.color || colors.primary} />
            </Flex>
          </Flex>
        </Link>
        <ModalComponent href={restprops.href as string} close={close} />
      </>
    )

  return (
    <>
      <Link target='_blank' rel='noopener noreferrer' onClick={e => [e.preventDefault(), open()]} {...restprops}>
        <Flex as='span' sx={{ alignItems: ['center'], ...styles }}>
          {children}
          {!hideIcon && (
            <Text as='span' ml={5}>
              <IconSelector size={icon?.size || 13} color={icon?.color || colors.primary} />
            </Text>
          )}
        </Flex>
      </Link>
      <ModalComponent href={restprops.href as string} close={close} />
    </>
  )
}

export default ExternalLink
