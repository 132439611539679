import React, { useRef } from 'react'
import { SearchOnClickType, useSearch } from 'contexts/search'
import useTheme from 'hooks/common/useTheme'
import { useTranslation } from 'next-i18next'
import { Box, BoxProps } from 'rebass/styled-components'
import { Button, IconSearch, Input } from 'theme/ui'

interface ISearch extends Omit<BoxProps, 'onClick'> {
  onClick?: SearchOnClickType
  borderColor?: string
  label?: string
}

const Search: React.FC<ISearch> = ({ onClick, borderColor = 'black', label, ...restprops }) => {
  const { t: tc } = useTranslation('common')
  const { colors } = useTheme()
  const { search, setSearch, clearSearch } = useSearch(onClick)
  const searchRef = useRef<HTMLInputElement>(null)
  const handleSearch = React.useCallback(() => {
    if (!search.length || !onClick) return
    onClick(search)
    clearSearch()
  }, [clearSearch, onClick, search])

  return (
    <Box {...restprops}>
      <Input
        name='search'
        placeholder={label || tc('label.search')}
        height={30}
        borderColor={borderColor}
        value={search}
        radiusSize='sm'
        fontSize={13}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        endAdornment={
          onClick && (
            <Box height={22} pr={20} pt={0} pb={0}>
              <Button size='icon' color='transparent' fontColor='primary' onClick={handleSearch}>
                <IconSearch size={20} />
              </Button>
            </Box>
          )
        }
        sx={{
          borderRadius: 'sm',
          transitionProperty: 'all',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in',
          px: 20,
          color: colors.black,
          '&::placeholder': {
            color: 'grey.300'
          }
          // '&:focus::placeholder': {
          //   color: colors.black
          // }
        }}
        ref={searchRef}
      />
    </Box>
  )
}

export default Search

export const MobileSearch: React.FC<ISearch> = ({ onClick, ...restprops }) => {
  const { search, setSearch, clearSearch } = useSearch(onClick)
  const { colors } = useTheme()
  const [showField, toggleField] = React.useState<boolean>(false)
  const handleSearch = React.useCallback(() => {
    if (!search.length || !onClick) return
    onClick(search)
    clearSearch()
    toggleField(false)
  }, [clearSearch, onClick, search])
  return (
    <Box {...restprops}>
      {!showField ? (
        <Button p={18} onClick={() => toggleField(true)}>
          <IconSearch size={20} />
        </Button>
      ) : (
        <Input
          name='search'
          placeholder='Search'
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          endAdornment={
            onClick && (
              <Box height={20} mr={20}>
                <Button color='transparent' fontColor='black' onClick={handleSearch}>
                  <IconSearch size={20} />
                </Button>
              </Box>
            )
          }
          sx={{
            px: 20,
            color: 'yellow.100',
            '&::placeholder': {
              color: colors.blue[1000]
            }
          }}
          styles={{
            borderColor: 'primary',
            color: 'black',
            bg: 'yellow.100',
            width: 142,
            ml: 'auto'
          }}
        />
      )}
    </Box>
  )
}
