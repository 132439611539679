import { ChevronDown } from 'react-feather'
import { merlinImagePath, merlinTestnetImagePath } from 'constants/index'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { Box, Flex, Image, Text } from 'rebass/styled-components'
import { getCurrentEndpointMeta } from 'utils'

import DropDown from 'components/common/dropw-down-v2'
import Link from 'components/common/link'

const style = {
  mt: 8,
  width: 185,
  ml: [-20, -20, 0]
}

const menuWrapperStyle = {
  pl: 0,
  pt: 0,
  pr: 0,
  pb: 0
}

interface INetworkSelect {
  border: boolean
}

const NetworkSelect: React.FC<INetworkSelect> = ({ border }) => {
  return (
    <DropDown
      disableModal
      buttonComponent={<DropdownButton {...{ border }} />}
      menuComponent={<Menu />}
      menuStyle={style}
      menuWrapperStyle={menuWrapperStyle}
    />
  )
}

export default NetworkSelect

const DropdownButton: React.FC<INetworkSelect> = ({ border }) => {
  const data = getCurrentEndpointMeta()
  const { colors } = useTheme()
  const { isTablet } = useResponsive()

  return (
    <Flex
      sx={{
        borderRadius: 'sm',
        mr: [-3, -3, 0],
        bg: 'white',
        position: 'relative',
        border: `1px solid ${border ? colors.grey[2] : 'transparent'}`,
        width: isTablet ? 185 : 45,
        py: 5.5,
        px: [5, 5, 10],
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Flex width='100%' alignItems='center' lineHeight='md'>
        <Flex width={20} height={20} sx={{ borderRadius: 'sm', overflow: 'hidden' }}>
          <Image src={data?.icon} />
        </Flex>
        {isTablet && (
          <>
            <Text ml={8} fontWeight='bold' fontSize='md'>
              {data.networkName}
            </Text>
            <Text ml={3} fontSize='md'>
              {data.networkType}
            </Text>
          </>
        )}
      </Flex>
      <ChevronDown color='black' strokeWidth={3} size={12} />
    </Flex>
  )
}

interface CustomEndpointProps {
  networkName: string
  networkType: string
  icon: string
  URL: string
}

const Menu: React.FC = () => {
  const { colors } = useTheme()

  const MERLIN_MAINNET_URL = 'https://merlin.benddao.xyz'
  const MERLIN_TESTNET_URL = 'https://merlin-testnet.benddao.xyz'

  const data: CustomEndpointProps[] = [
    { networkName: 'Merlin', networkType: 'Mainnet', icon: merlinImagePath, URL: MERLIN_MAINNET_URL },
    { networkName: 'Merlin', networkType: 'Testnet', icon: merlinTestnetImagePath, URL: MERLIN_TESTNET_URL }
  ]

  const isSelected = (item: CustomEndpointProps): boolean => item?.networkType === 'Testnet' && item?.networkName === 'Merlin'

  return (
    <Box
      width='100%'
      sx={{
        display: 'grid'
      }}
    >
      {data?.map((item: CustomEndpointProps) => (
        <Link key={item.networkName} href={item?.URL} passHref>
          <Flex
            sx={{
              width: '100%',
              alignItems: 'center',
              py: 10,
              pl: 10,
              borderRadius: 'sm',
              background: isSelected(item) ? colors.grey[50] : null,
              ':hover': { background: colors.grey[50], cursor: 'pointer' }
            }}
          >
            <Flex width={20} height={20} sx={{ borderRadius: 'icon', overflow: 'hidden' }}>
              <Image src={item?.icon} />
            </Flex>
            <Text ml={8} as='span' fontWeight='bold' fontSize='md'>
              {item?.networkName}
            </Text>
            <Text ml={3} as='span' fontSize='md'>
              {item?.networkType}
            </Text>
          </Flex>
        </Link>
      ))}
    </Box>
  )
}
