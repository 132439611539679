import { getAddress } from '@ethersproject/address'
import { Connector } from '@web3-react/types'
import * as bitcoin from 'bitcoinjs-lib'

import {
  coinbaseWalletConnection,
  ConnectionType,
  gnosisSafeConnection,
  injectedConnection,
  networkConnection,
  okxConnection,
  trustWalletConnection
} from '../connection'
import { walletConnectConnectionV2 } from '../connection/walletConnectV2'
import { ChainId } from '../constants'

export function getIsInjected(): boolean {
  return Boolean(window.ethereum)
}

export function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false
}

export function getIsCoinbaseWallet(): boolean {
  return window.ethereum?.isCoinbaseWallet ?? false
}

export function getIsOkxWallet(): boolean {
  return window.ethereum?.isOkxWallet ?? false
}

export function getIsTrustWallet(): boolean {
  return window.ethereum?.isTrustWallet ?? false
}

export function getIsUniSat(): boolean {
  return !!(window as any)?.unisat
}

const CONNECTIONS = [
  gnosisSafeConnection,
  injectedConnection,
  coinbaseWalletConnection,
  okxConnection,
  trustWalletConnection,
  networkConnection,
  walletConnectConnectionV2
]
export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = CONNECTIONS.find(connection => connection.connector === c)
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return injectedConnection
      case ConnectionType.COINBASE_WALLET:
        return coinbaseWalletConnection
      case ConnectionType.WALLET_CONNECT_V2:
        return walletConnectConnectionV2
      case ConnectionType.OKX_WALLET:
        return okxConnection
      case ConnectionType.TRUST_WALLET:
        return trustWalletConnection
      case ConnectionType.NETWORK:
        return networkConnection
      case ConnectionType.GNOSIS_SAFE:
        return gnosisSafeConnection
    }
  }
}

export function getConnectionName({
  connectionType,
  isMetaMask,
  isCoinbaseWallet,
  isOkxWallet
}: {
  connectionType: ConnectionType
  isMetaMask?: boolean
  isCoinbaseWallet?: boolean
  isOkxWallet?: boolean
}) {
  switch (connectionType) {
    case ConnectionType.INJECTED:
      return isOkxWallet ? 'OKX Wallet' : isMetaMask ? 'MetaMask' : isCoinbaseWallet ? 'Coinbase Wallet' : 'Injected'
    case ConnectionType.COINBASE_WALLET:
      return 'Coinbase Wallet'
    case ConnectionType.WALLET_CONNECT_V2:
      return 'WalletConnect'
    case ConnectionType.OKX_WALLET:
      return 'OKX Wallet'
    case ConnectionType.TRUST_WALLET:
      return 'Trust Wallet'
    case ConnectionType.NETWORK:
      return 'Network'
    case ConnectionType.GNOSIS_SAFE:
      return 'Gnosis Safe'
  }
}

// returns the checksummed address if the address is valid, otherwise returns false
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getValidBtcAddress(input: string): string | false {
  const regex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/i
  const bech32Regex = /^tb1[a-zA-HJ-NP-Z0-9]{25,39}$/i
  if (!regex.test(input) && !bech32Regex.test(input)) {
    return false
  }
  return input
}

export function isValidBtcAddress(input: string): boolean {
  const regex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/i
  const bech32Regex = /^tb1[a-zA-HJ-NP-Z0-9]{25,39}$/i
  if (!regex.test(input) && !bech32Regex.test(input)) {
    return false
  }
  return true
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4, isBtcAddress = false): string {
  if (isBtcAddress) return `${address.substring(0, chars)}...${address.substring(address.length - chars)}`
  if (!address) return ''
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function shortenBtcAddress(address: string, chars = 4): string {
  if (!address) return ''
  const parsed = address
  if (!parsed) {
    return 'Invalid address'
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(parsed?.length - chars)}`
}

export function isTaprootAddress(address: string): boolean {
  try {
    const decoded = bitcoin.address.fromBech32(address)
    if (decoded && (address.toLowerCase().startsWith('bc1p') || address.toLowerCase().startsWith('tb1p'))) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

const MERLIN_PREFIXES: {
  [key: number]: string
} = {
  4200: '',
  686868: 'testnet-'
}

export function getEtherscanLink(chainId: ChainId = 4200, data: string, type: 'transaction' | 'token' | 'address'): string {
  const chainIdInt = parseInt(chainId.toString())
  let prefix = ''
  if (chainIdInt === 4200 || chainIdInt === 686868) {
    prefix = `https://${MERLIN_PREFIXES[chainId] || MERLIN_PREFIXES[4200]}scan.merlinchain.io/`
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}
