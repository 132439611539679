import { createContext, useContext, useEffect, useReducer } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { ApolloQueryResult, gql } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BTC, nftImageUrlType, USDT } from 'constants/index'
import { DataReducer, IMarketNftCollection, IMarketReserve, LoanState, ReducerActions, ReservePayload } from 'constants/types'
import { GraphQLError } from 'graphql'
import useUsdPriceBend from 'hooks/common/useUsdPriceBend'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { getApeStakingV2TvlData } from 'modules/apecoin-staking-v2/api/tvl'
import getLendPools from 'modules/bend/api/lend-pool/lend-pools'
import { WETH_ADDRESS } from 'modules/bend/constants'
import { isMerlinChain, isSupportedFeature, powDecimals } from 'utils'

import { clientBendProtocol, clientNftApi } from '../clients'

/** ------------------------------------------- **
 *  Data context initialization
 ** ------------------------------------------- */

/**
 * Interfaces & types
 */
interface State {
  marketReserves: IMarketReserve[]
  marketNftCollections: IMarketNftCollection[]
  marketReservesErrors: GraphQLError[]
  marketNftCollectionsErrors: GraphQLError[]
  dashboardMyDeposits: { [x: string]: Reserve }
  dashboardMyDepositsCount: number
  dashboardActiveReserve: string
  totalValueDeposited: BigNumber
  totalPoolValue: BigNumber
  first: number
  collateralKey: string | undefined
  showAllCollaterals: boolean
  usdPriceEth: {
    value: BigNumber
    isLoading: boolean
  }
  usdPriceBend: {
    value: BigNumber
    isLoading: boolean
  }
  apeStakingV2Tvl: {
    value: BigNumber | null
    isLoading: boolean
  }
  eth: ReservePayload | null
  btc: ReservePayload | null
  usdt: ReservePayload | null
}

interface DataContext extends State {
  dispatch?: any
  reservesQuery?: any
  collateralsQuery?: any
  collateralQuery?: any
  borrowingsQuery?: any
  totalBorrowingsQuery?: any
  incentiveQuery?: any
  activeAuctionsQuery?: any
  totalActiveAuctionsQuery?: any
  totalBorrowsInAuctions?: any
  totalBorrowsCountQuery?: any
}

// const QUERY_RESERVE = gql`
//   query Reserves($weth: String) {
//     reserves(where: { isActive: true, isFrozen: false, underlyingAsset: $weth }) {
//       id
//       decimals
//       underlyingAsset
//       symbol
//       name
//       totalLiquidity
//       totalCurrentVariableDebt
//       totalBTokenSupply
//       liquidityRate
//       variableBorrowRate
//       utilizationRate
//       isActive
//       isFrozen
//       availableLiquidity
//       price {
//         priceInEth
//       }
//       bToken {
//         id
//       }
//       debtToken {
//         id
//       }
//     }
//     distributionManagers {
//       id
//     }
//   }
// `

/** @constant {State} */
const initialState: State = {
  marketReserves: [],
  marketReservesErrors: [],
  marketNftCollections: [],
  marketNftCollectionsErrors: [],
  dashboardMyDeposits: {},
  dashboardMyDepositsCount: 3,
  dashboardActiveReserve: '',
  totalValueDeposited: new BigNumber(0),
  totalPoolValue: new BigNumber(0),
  first: 4,
  collateralKey: undefined,
  showAllCollaterals: false,
  usdPriceEth: {
    value: new BigNumber(0),
    isLoading: true
  },
  usdPriceBend: {
    value: new BigNumber(0),
    isLoading: true
  },
  apeStakingV2Tvl: {
    value: null,
    isLoading: true
  },
  eth: null,
  btc: null,
  usdt: null
}

/**
 * Data context init
 * @param {DataContext} initialState
 */
const DataContext = createContext<DataContext>(initialState)

/**
 * Context reducer
 * @param {State} state
 * @param {string} action
 * @param {any} payload
 */
const reducer = (state: State, { action, payload }: ReducerActions<DataReducer>) => {
  switch (action) {
    default:
      return state
    case DataReducer.setMarketReserves:
      let sortedPayload: IMarketReserve[] = []
      if (isArray(payload)) {
        const ethIndex = findIndex(payload, (o: IMarketReserve) => o.assetTicker === BTC.symbol)
        sortedPayload = [payload[ethIndex], ...filter(payload, (_, index) => index !== ethIndex)]
      }
      return { ...state, marketReserves: sortedPayload || [], marketReservesError: [] }
    case DataReducer.setMarketReservesError:
      return { ...state, marketReserves: [], marketReservesError: payload }
    case DataReducer.setMarketNftCollections:
      return { ...state, marketNftCollections: payload, marketNftCollectionsError: [] }
    case DataReducer.setMarketNftCollectionsError:
      return { ...state, marketNftCollections: [], marketNftCollectionsError: payload }
    case DataReducer.setDashboardMyDeposits:
      const weth = {
        [WETH_ADDRESS.toLocaleLowerCase()]: payload[WETH_ADDRESS.toLocaleLowerCase()]
      }
      return {
        ...state,
        dashboardMyDeposits: isEmpty(Object.keys(payload)) ? {} : { ...weth, ...payload },
        dashboardActiveReserve: state.dashboardActiveReserve ? state.dashboardActiveReserve : WETH_ADDRESS.toLocaleLowerCase()
      }
    // case DataReducer.setDashboardMyDepositsETH:
    //   return { ...state, dashboardMyDeposits: { ...payload, ...state.dashboardMyDeposits } }
    case DataReducer.setDashboardMyDepositsCount:
      return { ...state, dashboardMyDepositsCount: payload }
    case DataReducer.setDashboardActiveReserve:
      return { ...state, dashboardActiveReserve: payload }
    case DataReducer.cleanupReserves:
      return { ...state, marketReserves: [] }
    case DataReducer.addToReservesBalance:
      return { ...state, totalValueDeposited: payload }
    case DataReducer.setCollateralKey:
      return { ...state, collateralKey: payload }
    case DataReducer.setShowAllCollaterals:
      return { ...state, showAllCollaterals: payload }
    case DataReducer.setUsdPriceEth:
      return { ...state, usdPriceEth: { ...state.usdPriceEth, ...payload } }
    case DataReducer.setUsdPriceBend:
      return { ...state, usdPriceBend: { ...state.usdPriceBend, ...payload } }
    case DataReducer.cleanUpTotalValueDeposited:
      return { ...state, totalValueDeposited: new BigNumber(0) }
    case DataReducer.setBtc:
      return { ...state, btc: payload }
    case DataReducer.setEth:
      return { ...state, eth: payload }
    case DataReducer.setUsdt:
      return { ...state, usdt: payload }
    case DataReducer.setApeStakingV2Tvl:
      return { ...state, apeStakingV2Tvl: { ...state.apeStakingV2Tvl, value: payload.value } }
    case DataReducer.setApeStakingV2TvlLoading:
      return { ...state, apeStakingV2Tvl: { ...state.apeStakingV2Tvl, isLoading: payload.isLoading } }
    case DataReducer.cleanup:
      return { ...initialState }
  }
}

/**
 * useDataContextHook
 */
export const useDataContext = () => useContext(DataContext)

/**
 * DataContextProvider
 * @param {ReactElement} children
 * @return {React.ReactElement}
 */
const DataContextProvider: React.FC = ({ children }): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { account, provider } = useWeb3React()
  const { totalBorrowingsQuery, totalActiveAuctionsQuery } = useMyDashboardCollateralsAndBorrowings(/* state */)
  const { usdPriceBend, isLoading: bendPriceInEthIsLoading } = useUsdPriceBend(state.usdPriceEth)
  // const [usdPriceEthLoading, setUsdPriceEthLoading] = useState(true)
  useQuery(
    ['get base price', state.usdPriceEth.value.toFixed(4)],
    async () => {
      // const {
      //   data: { priceOracle }
      // } = await clientBendProtocol.query({
      //   query: gql`
      //     query PriceOracleEth($id: ID!) {
      //       priceOracle(id: $id) {
      //         usdPriceEth
      //         usdPriceDecimals
      //       }
      //     }
      //   `,
      //   variables: {
      //     id: 1
      //   }
      // })

      const {
        data: { baseTokenPriceInUSD }
      }: ApolloQueryResult<{
        baseTokenPriceInUSD: {
          price: string
          decimals: number
        }
      }> = await clientNftApi.query({
        query: gql`
          query BaseTokenPriceInUSD {
            baseTokenPriceInUSD {
              price
              decimals
            }
          }
        `
      })

      return baseTokenPriceInUSD
    },
    {
      onError: error => {
        console.error('error fetching ethusd price', error)
      },
      onSuccess: priceOracle => {
        console.log('basePriceInUSD', new BigNumber(priceOracle?.price).dividedBy(`1e${priceOracle?.decimals}`).toFixed(4))
        dispatch({
          action: DataReducer.setUsdPriceEth,
          payload: {
            value: new BigNumber(priceOracle?.price).dividedBy(`1e${priceOracle?.decimals}`),
            isLoading: false
          }
        })
      },
      refetchInterval: 60000,
      retry: 5
    }
  )

  useEffect(() => {
    if (!usdPriceBend?.toFixed()) return
    if (!isMerlinChain) console.log('bendPriceUsd', usdPriceBend?.toFixed())
    dispatch({
      action: DataReducer.setUsdPriceBend,
      payload: {
        value: usdPriceBend,
        isLoading: bendPriceInEthIsLoading
      }
    })
  }, [bendPriceInEthIsLoading, usdPriceBend])

  // const fetchEth = useCallback(async () => {
  //   try {
  //     const {
  //       data: { reserves, distributionManagers }
  //     }: any = await clientBendProtocol.query({
  //       query: QUERY_RESERVE,
  //       variables: {
  //         weth: WETH_ADDRESS
  //       }
  //     })

  //     dispatch({
  //       action: DataReducer.setEth,
  //       payload: reservePayload(reserves[0], distributionManagers[0]?.id)
  //     })
  //   } catch (e) {
  //     console.log('fetchEth error', e)
  //   }
  // }, [])

  // const fetchUsdt = useCallback(async () => {
  //   try {
  //     const {
  //       data: { reserves, distributionManagers }
  //     }: any = await clientBendProtocol.query({
  //       query: QUERY_RESERVE,
  //       variables: {
  //         weth: USDT_ADDRESS
  //       }
  //     })

  //     dispatch({
  //       action: DataReducer.setUsdt,
  //       payload: reservePayload(reserves[0], distributionManagers[0]?.id)
  //     })
  //   } catch (e) {
  //     console.log('fetchUsdt error', e)
  //   }
  // }, [])

  // useEffect(() => {
  //   fetchEth()
  // }, [fetchEth])

  const totalBorrowsInAuctions = useQuery(
    ['user active borrows in auction', account],
    async () => {
      const {
        data: { loans }
      } = await clientBendProtocol.query({
        query: gql`
          query LoansByAddressAndState($borrower: Bytes, $state: LoanState) {
            loans(where: { borrower: $borrower, state: Auction }) {
              id
            }
          }
        `,
        variables: {
          borrower: account?.toLowerCase(),
          state: LoanState.Auction
        }
      })

      return new BigNumber(loans.length)
    },
    {
      enabled: !!account
    }
  )

  const totalBorrowsCountQuery = useQuery(
    ['get total borrows count'],
    async () => {
      const {
        data: { loans }
      } = await clientBendProtocol.query({
        query: gql`
          query LoansNum($borrower: String, $state_in: [LoanState]) {
            loans(where: { borrower: $borrower, state_in: $state_in }) {
              id
            }
          }
        `,
        variables: {
          borrower: account?.toLowerCase(),
          state_in: [LoanState.Active, LoanState.Auction]
        }
      })

      return loans.length
    },
    {
      enabled: !!account
    }
  )

  useEffect(() => {
    getLendPools({}).then(reserves => {
      dispatch({
        action: DataReducer.setEth,
        payload: find(reserves, item => item.assetTicker === BTC.symbol)
      })
      dispatch({
        action: DataReducer.setUsdt,
        payload: find(reserves, item => item.assetTicker === USDT.symbol)
      })
    })
  }, [])

  const { isLoading: apeStakingV2isLoading } = useQuery(
    ['get ape staking v2 tvl', state.usdPriceEth.value.toFixed(4)],
    async () => {
      if (!isSupportedFeature('apestaking')) return new BigNumber(0)
      const ethPriceUsd = state.usdPriceEth.value
      const data = await getApeStakingV2TvlData({ provider })

      const baycTVL = data.totalStakedNft.bayc?.multipliedBy(data.floorPrice.bayc)
      const maycTVL = data.totalStakedNft.mayc?.multipliedBy(data.floorPrice.mayc)
      const bakcTVL = data.totalStakedNft.bakc?.multipliedBy(data.floorPrice.bakc)
      const apeCoinTVL = data.totalStakedNft.apeCoin?.multipliedBy(data.assetPriceETH.apeCoin)

      // console.group('ape staking v2 tvl')
      // console.log({
      //   baycTVL: data.totalStakedNft.bayc?.multipliedBy(data.floorPrice.bayc).multipliedBy(ethPriceUsd)?.toFixed(4) + ' USD',
      //   baycTotalStaked: data.totalStakedNft.bayc?.toFixed(),
      //   baycFloorPrice: data.floorPrice.bayc?.toFixed()
      // })

      // console.log({
      //   maycTVL: data.totalStakedNft.mayc?.multipliedBy(data.floorPrice.mayc).multipliedBy(ethPriceUsd)?.toFixed(4) + ' USD',
      //   maycTotalStaked: data.totalStakedNft.mayc?.toFixed(),
      //   maycFloorPrice: data.floorPrice.mayc?.toFixed()
      // })

      // console.log({
      //   bakcTVL: data.totalStakedNft.bakc?.multipliedBy(data.floorPrice.bakc).multipliedBy(ethPriceUsd)?.toFixed(4) + ' USD',
      //   bakcTotalStaked: data.totalStakedNft.bakc?.toFixed(),
      //   bakcFloorPrice: data.floorPrice.bakc?.toFixed()
      // })

      // console.log({
      //   apeCoinTVL: data.totalStakedNft.apeCoin?.multipliedBy(data.assetPriceETH.apeCoin)?.multipliedBy(ethPriceUsd)?.toFixed(4) + ' USD',
      //   apeCoinTotalStaked: data.totalStakedNft.apeCoin?.toFixed(),
      //   apeCoinFloorPrice: data.assetPriceETH.apeCoin?.toFixed()
      // })

      // console.log({ apeStakingV2Tvl: new BigNumber(baycTVL.plus(maycTVL).plus(bakcTVL).plus(apeCoinTVL)).multipliedBy(ethPriceUsd)?.toFixed(4) + ' USD' })
      // console.groupEnd()

      return new BigNumber(baycTVL.plus(maycTVL).plus(bakcTVL).plus(apeCoinTVL)).multipliedBy(ethPriceUsd)
    },
    {
      enabled: state.usdPriceEth.value.gt(0),
      onSuccess: data => {
        dispatch({
          action: DataReducer.setApeStakingV2Tvl,
          payload: {
            value: data
          }
        })
      },
      onError: error => {
        console.error('error fetching ape staking v2 tvl', error)
        dispatch({
          action: DataReducer.setApeStakingV2Tvl,
          payload: {
            value: new BigNumber(0)
          }
        })
      }
    }
  )

  useEffect(() => {
    dispatch({
      action: DataReducer.setApeStakingV2TvlLoading,
      payload: {
        isLoading: apeStakingV2isLoading
      }
    })
  }, [apeStakingV2isLoading])

  return (
    <DataContext.Provider
      value={{
        ...state,
        dispatch,
        totalBorrowingsQuery,
        totalActiveAuctionsQuery,
        totalBorrowsInAuctions,
        totalBorrowsCountQuery
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataContextProvider

/** ------------------------------------------- **
 *  Hook useMyDashboardDeposits
 ** ------------------------------------------- */

/**
 * Interfaces & types
 */
interface Price {
  priceInEth: BigNumber
  priceDecimals: number
  oracle: {
    usdPriceEth: BigNumber
    usdPriceDecimals: number
  }
}
export interface Reserve extends UserReserve {
  id: string
  symbol: string
  name: string
  underlyingAsset: string
  totalLiquidity: BigNumber
  liquidityRate: BigNumber
  variableBorrowRate: BigNumber
  decimals: number
  price: Price
  userReserves?: UserReserve[]
  totalBTokenSupply: BigNumber
  totalCurrentVariableDebt: BigNumber
  bToken: {
    id: string
  }
  debtToken: {
    id: string
  }
  distributionManager: string
  isActive: boolean
  isFrozen: boolean
}

interface UserReserve {
  scaledBTokenBalance: BigNumber
  currentBTokenBalance: BigNumber
  scaledVariableDebt: BigNumber
  currentVariableDebt: BigNumber
  currentTotalDebt: BigNumber
  variableBorrowIndex: BigNumber
}

/** ------------------------------------------- **
 *  Hook useMyDashboardCollateralsAndBorrowings
 ** ------------------------------------------- */

/**
 * Interfaces & types
 */

export interface NftAsset {
  symbol: string
  name: string
  nftAsset?: string
  underlyingAsset: string
  baseLTVasCollateral: BigNumber
  liquidationThreshold: BigNumber
  price: NftPrice
}

export interface NftItemCollection {
  name: string
  symbol: string
  openseaImageURL: string
}

export interface Collection {
  nftId: string
  collectionAddress: string
  ownerAddress: string
  tokenID: string
  image: string
  imageType: nftImageUrlType
  nftAsset: NftAsset
  floorPrice: BigNumber
  availableToBorrow: BigNumber
  collection: NftItemCollection
  bnftToken: string
}

export interface NftItems {
  nftItems: Collection[]
}

export interface NftItem {
  image: string
  imageType: nftImageUrlType
  collection: {
    openseaImageURL: string
  }
}
export interface NftItemData {
  data: {
    nftItem: NftItem
  }
}

export interface NftPrice {
  priceInEth: BigNumber
  oracle?: {
    usdPriceEth: BigNumber
  }
}
export interface Nfts {
  id: string
  baseLTVasCollateral: BigNumber
  price: NftPrice
  liquidationThreshold: BigNumber
  name: string
  symbol: string
  underlyingAsset: string
  bnftToken: string
}

export interface LoanReserveAsset {
  underlyingAsset: string
  variableBorrowRate: BigNumber
  decimals: number
  name: string
  symbol: string
  price: NftPrice
}
export interface Loan {
  id: string
  reserveAsset: LoanReserveAsset
  nftTokenId: string
  currentAmount: BigNumber
  nftAsset: NftAsset
  image: string
  imageType: nftImageUrlType
  openseaImageURL: string
  loanId: string
  healthFactor: number
  state: LoanState
  availableToBorrow: BigNumber
}
export interface NftLoan {
  healthFactor: number
  currentAmount: string
}

export interface NftLoanData {
  data: {
    loans: NftLoan
  }
}

/**
 * GraphQL Queries
 */
export const QUERY_NFTS_BY_UNDERLYINGASSET = gql`
  query NftsByUnderlyingAsset($underlyingAssets: [Bytes!]) {
    nfts(where: { underlyingAsset_in: $underlyingAssets }) {
      id
      symbol
      name
      underlyingAsset
      liquidationThreshold
      baseLTVasCollateral
      bnftToken {
        id
      }
      price {
        priceInEth
      }
    }
  }
`

const nftItems = `
  collectionAddress
  tokenID
  image
  imageType
  collection {
    name
    symbol
    openseaImageURL
  }
`

export const NFTITEMS_BY_OWNER_ADDRESS = gql`
  query NftItemsByOwnerAddress($address: String!) {
    nftItems(ownerAddress: $address) {
      ${nftItems}
    }
  }
`

export const NFTITEMS_BY_OWNER_ADDRESS_WITH_PAGINATION = gql`
  query NftItemsByOwnerAddress($address: String!, $first: Int, $skip: Int) {
    nftItems(ownerAddress: $address, first: $first, skip: $skip) {
      ${nftItems}
    }
  }
`

export const NFTITEM_BY_COLLECTION_ADDRESS_AND_TOKEN_ID = gql`
  query NftItemByCollectionAddressAndTokenID($collectionAddress: String!, $tokenID: String!) {
    nftItem(collectionAddress: $collectionAddress, tokenID: $tokenID) {
      ${nftItems}
    }
  }
`

export const NFTITEMIMAGE_BY_COLLECTION_ADDRESS_AND_TOKEN_ID = gql`
  query NftItemImageByCollectionAddressAndTokenID($collectionAddress: String!, $tokenID: String!) {
    nftItem(collectionAddress: $collectionAddress, tokenID: $tokenID) {
      image
      imageType
      collection {
        openseaImageURL
      }
    }
  }
`

export const QUERY_LOANS_BY_USER = gql`
  query Loans($borrower: String, $first: Int, $skip: Int, $state: [LoanState!]) {
    loans(where: { borrower: $borrower, state_in: $state }, first: $first, skip: $skip) {
      id
      state
      loanId
      nftTokenId
      reserveAsset {
        underlyingAsset
        variableBorrowRate
        decimals
        name
        symbol
        price {
          priceInEth
          oracle {
            usdPriceEth
          }
        }
      }
      currentAmount
      nftAsset {
        underlyingAsset
        symbol
        name
        bnftToken
        baseLTVasCollateral
        liquidationThreshold
        price {
          priceInEth
        }
      }
      loanBalanceHistory(orderBy: timestamp, orderDirection: desc) {
        currentAmount
      }
    }
  }
`

export const QUERY_NFTLOAN_BY_LOANID = gql`
  query Loans($loanID: String) {
    loans(loanID: $loanID) {
      healthFactor
    }
  }
`

interface LoanCurrentAmountByBidderAddress {
  reserveAsset: {
    decimals: number
    price: Price
  }
  bidPrice: BigNumber
}

interface LoansCurrentAmountByBidderAddressResult {
  loans: LoanCurrentAmountByBidderAddress[]
}

export const QUERY_LOANS_BY_BIDDER_ADDRESS = gql`
  query LoansByBidderAddress($bidderAddress: String!, $first: Int, $skip: Int, $state: LoanState!) {
    loans(where: { bidderAddress: $bidderAddress, state: $state }, first: $first, skip: $skip) {
      id
      loanId
      bidStartTimestamp
      nftAsset {
        underlyingAsset
        auctionDuration
        symbol
        price {
          priceInEth
        }
      }
      nftTokenId
      state
      bidderAddress
      bidPrice
      currentAmount
      reserveAsset {
        underlyingAsset
        decimals
        price {
          priceInEth
        }
      }
    }
  }
`

const QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS_FRAGMENT = `
  reserveAsset {
    decimals
    price {
      priceInEth
      priceDecimals
      oracle {
        usdPriceEth
        usdPriceDecimals
      }
    }
  }
  bidPrice
`

export const QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS = gql`
  query LoansCurrentAmountByBidderAddress($bidderAddress: String!, $state: LoanState!) {
    loans(where: { bidderAddress: $bidderAddress, state: $state }) {
      ${QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

export const QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS_TESTNET = gql`
  query LoansCurrentAmountByBidderAddress($bidderAddress: String!, $state: LoanState!, $reserve: String) {
    loans(where: { bidderAddress: $bidderAddress, state: $state, reserveAsset: $reserve }) {
      ${QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS_FRAGMENT}
    }
  }
`

export const QUERY_NFTLOANS_BY_BIDDER_ADDRESS = gql`
  query ($state: LoanState) {
    loans(state: $state) {
      loanID
      state
      healthFactor
      nftTokenID
      nftItem {
        image
        imageType
        collection {
          name
          openseaImageURL
        }
      }
    }
  }
`

// interface GetCollaetralDetails {
//   nftItems: Collection[]
// }

const useMyDashboardCollateralsAndBorrowings = (): // state: State
  {
    totalBorrowingsQuery: UseQueryResult<any, any>
    totalActiveAuctionsQuery: UseQueryResult<any, any>
  } => {
  const { account } = useWeb3React()

  const totalBorrows: UseQueryResult<any, any> = useQuery(
    ['user total borrows', account],
    async () => {
      let currentAmount = new BigNumber(0)
      if (!account) return currentAmount
      const {
        data: { loans }
      }: ApolloQueryResult<{ loans: Loan[] }> = await clientBendProtocol.query({
        query: QUERY_LOANS_BY_USER,
        variables: {
          borrower: account.toLocaleLowerCase(),
          state: [LoanState.Active, LoanState.Auction]
        }
      })

      if (isEmpty(loans)) return currentAmount

      for (let i = 0; i < loans.length; i += 1) {
        currentAmount = currentAmount.plus(
          new BigNumber(loans[i].currentAmount)
            .dividedBy(`1e${loans[i].reserveAsset.decimals}`)
            .multipliedBy(new BigNumber(loans[i].reserveAsset.price.priceInEth).dividedBy(1e18))
          // .multipliedBy(state.usdPriceEth.value)
          // .multipliedBy(new BigNumber(loans[i].reserveAsset.price.oracle!.usdPriceEth).dividedBy(1e8))
        )
      }

      return currentAmount
    },
    {
      enabled: !!account,
      onError: error => {
        console.log('❌ user total borrows graphql error', error)
      }
    }
  )

  const totalActiveBids: UseQueryResult<any, any> = useQuery(
    ['user total active bids', account],
    async () => {
      let currentAmount = new BigNumber(0)
      if (!account) return currentAmount
      const {
        data: { loans }
      }: ApolloQueryResult<LoansCurrentAmountByBidderAddressResult> = await clientBendProtocol.query({
        query: QUERY_LOANS_CURRENT_AMOUNT_BY_BIDDER_ADDRESS,
        variables: {
          bidderAddress: account.toLocaleLowerCase(),
          state: LoanState.Auction
          // reserve: WETH_ADDRESS.toLowerCase() + LEND_POOL_ADDRESSES_PROVIDER.toLowerCase()
        }
      })

      if (isEmpty(loans)) return currentAmount

      currentAmount = loans.reduce((acc, loan) => {
        const bidPrice = new BigNumber(loan.bidPrice).dividedBy(powDecimals(loan.reserveAsset.decimals))
        const reservePriceInEth = new BigNumber(loan.reserveAsset.price.priceInEth).dividedBy(powDecimals(loan.reserveAsset.price.priceDecimals))
        const usdPriceEth = new BigNumber(loan.reserveAsset.price.oracle!.usdPriceEth).dividedBy(powDecimals(loan.reserveAsset.price.oracle!.usdPriceDecimals))
        return acc.plus(bidPrice.multipliedBy(reservePriceInEth).multipliedBy(usdPriceEth))
      }, new BigNumber(0))

      return currentAmount
    },
    {
      enabled: !!account,
      refetchInterval: 15000,
      onError: error => {
        console.log('❌ user total active bids graphql error', error)
      }
    }
  )

  return {
    totalBorrowingsQuery: totalBorrows,
    totalActiveAuctionsQuery: totalActiveBids
  }
}
