import { useWeb3React } from '@web3-react/core'
import {
  merlinBtcImagePath,
  SHOW_DECIMAL_PLACES,
  WBTC_DECIMAL_PLACES,
  wbtcImagePath,
} from 'constants/index'
import { WBTC_ADDRESS } from 'modules/bend/constants'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Image, Text } from 'rebass/styled-components'
import { Avatar, Button } from 'theme/ui'
import { isWeth } from 'utils'

import NumberFormat from 'components/common/number-format'

export const WrapStep1: React.FC<any> = ({ reserves, balances, handleWrap, handleUnWrap }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  return (
    <Flex flexDirection='column' flex={1}>
      <Flex flexDirection='column' flex={1} mt={20}>
        <Box key='eth_balance' mb={10}>
          <Flex justifyContent='space-between' flex={1} alignItems='center'>
            <Avatar
              icon={
                <Flex maxHeight={32} maxWidth={32} sx={{ borderRadius: 'icon', overflow: 'hidden' }}>
                  <Image src={merlinBtcImagePath} height='100%' width='100%' />
                </Flex>
              }
              iconSize={32}
              text='Merlin BTC'
              fontSize='md'
            />
            <Text fontSize='md' fontWeight='bold'>
              {account ? <NumberFormat number={balances.eth} format={2} /> : '--'}
            </Text>
          </Flex>
        </Box>
        {reserves.map((reserve: any) => {
          if (isWeth(reserve.assetAddress))
            return (
              <Box key={reserve.key} mb={10}>
                <Flex justifyContent='space-between' flex={1} alignItems='center'>
                  <Avatar
                    icon={
                      <Flex maxHeight={32} maxWidth={32} sx={{ borderRadius: 'icon', overflow: 'hidden' }}>
                        <Image
                          src={isWeth(reserve.assetAddress) ? `/images/elements/${reserve.assetAddress}-dark.svg` : reserve.assetIcon}
                          height='100%'
                          width='100%'
                        />
                      </Flex>
                    }
                    iconSize={32}
                    text={reserve.assetName === 'ETH' ? 'WETH' : reserve.assetName}
                    fontSize='md'
                  />
                  <Text fontSize='md' fontWeight='bold'>
                    {account ? (
                      <NumberFormat number={balances.reserve[reserve.assetAddress]?.amount?.dividedBy(`1e${reserve.decimals}`)} format={SHOW_DECIMAL_PLACES} />
                    ) : (
                      '--'
                    )}
                  </Text>
                </Flex>
              </Box>
            )
        })}
        <Box key='wbtc_balance' mb={10}>
          <Flex justifyContent='space-between' flex={1} alignItems='center'>
            <Avatar
              icon={
                <Flex maxHeight={32} maxWidth={32} sx={{ borderRadius: 'icon', overflow: 'hidden' }}>
                  <Image src={wbtcImagePath} height='100%' width='100%' />
                </Flex>
              }
              iconSize={32}
              text='WBTC'
              fontSize='md'
            />
            <Text fontSize='md' fontWeight='bold'>
              {account ? <NumberFormat number={balances.reserve[WBTC_ADDRESS]?.amount?.dividedBy(`1e6`)} format={WBTC_DECIMAL_PLACES} /> : '--'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent='space-between'>
        <Button color='primary' size='md' fontWeight='bold' flex={0.48} onClick={() => handleWrap()} disabled={!account}>
          {t('button.wrap')}
        </Button>
        <Button color='primary' size='md' fontWeight='bold' flex={0.48} onClick={() => handleUnWrap()} disabled={!account}>
          {t('button.unwrap')}
        </Button>
      </Flex>
    </Flex>
  )
}
