/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MERLIN = 4200,
  MERLIN_TESTNET = 686868
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MERLIN]: 'merlin',
  [SupportedChainId.MERLIN_TESTNET]: 'merlin-testnet'
}

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId]
}

export const CHAIN_ID_DEFAUL_SYMBOL = {
  [SupportedChainId.MERLIN]: 'BTC',
  [SupportedChainId.MERLIN_TESTNET]: 'BTC'
}
